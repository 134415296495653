<template>
    <v-card>
        <v-card-title><h1>Oooops !!</h1></v-card-title>
        <v-card-text>
            <h3 v-if="title">{{ title }}</h3>
            <v-alert
                    v-if="message"
                    :type="messageType"
                    class="mt-3"
            >
                {{ message }}
            </v-alert>
            <v-btn
                    v-if="backToLink"
                    :href="backToLink">
                zurück
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "NotFound",
        props: {
            title: {
                type: String,
                default: 'Die gesuchte Seite wurde nicht gefunden!'
            },
            message: {
                type: String
            },
            messageType: {
                type: String,
                default: 'info'
            },
            backToLink: {
                type: String
            }
        }
    }
</script>

<style scoped>

</style>
