<template>
    <div>
        <v-overlay :value="showLoader">
            <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="10"
            />
        </v-overlay>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        computed: {
            showLoader: function () {
                return this.$store.getters.getShow
            }
        }
    }
</script>

<style scoped>

</style>
