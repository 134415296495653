import SLApi from './SLApi'
import Urls from "./Urls";

export default {
    getUser() {
        return SLApi.client.get(SLApi.encodeUrl(Urls.User.current()))
    },
    changeTenant(tenantDatabaseName) {
        return SLApi.client.put(SLApi.encodeUrl(Urls.User.current()), {TenantDatabaseName: tenantDatabaseName})
    }
}
