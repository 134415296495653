/**
 * Info
 */

import TypeChecks from "../../utils/typecheck";
import InfoService from "../../services/InfoService";
import {ERROR_HIDE, ERROR_SHOW} from "../actions/ApiClientError";
import ConnectionStatus from "../../utils/ConnectionStatus";

const state = {
    info: {
        IsBackendManagerRunning: null
    },
    version: {
        env: document.body.getAttribute('data-app-env'),
        version: null,
        hash: null,
        hash_long: null,
        buildDate: null,
        message: null,
    }
};

const getters = {
    info: state => state.info,
    isBackendManagerRunning: (state) => {
        return state.info.IsBackendManagerRunning
    },
    appEnv: state => state.version.env,
    appVersion: state => state.version.version,
    appBuildDate: state => state.version.buildDate,
    appGitHash: state => state.version.hash,
    appGitMessage: state => state.version.message,
};

const actions = {
    getInfo: ({commit, dispatch}) => {
        if (ConnectionStatus.isOnline()) {
            return InfoService.getInfo().then(response => {
                commit('info', response.data);
                dispatch('checkBackendManagerRunning');
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.errorMessage = error.response;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
            })
        }
    },
    checkBackendManagerRunning: ({commit, state, rootGetters}) => {
        if (!state.info.IsBackendManagerRunning) {
            commit(ERROR_SHOW, ({
                'url': '/Info',
                'message': 'SelectLine API Backend Manager läuft nicht!'
            }), {root: true});
        } else {
            if (rootGetters.getShowError) {
                commit(ERROR_HIDE, null, {root: true});
            }
        }
    },
    addInterval: ({dispatch}) => {
        let timerGetInfo = setInterval(() => {
            dispatch('getInfo');
        }, 10000);
        dispatch('timer/add', {ident: 'getInfo', number: timerGetInfo}, {root: true})
    }
};

const mutations = {
    info: (state, info) => {
        if (TypeChecks.isObject(info)) {
            state.info = info;
        }
    },
    version: (state, version) => {
        if (TypeChecks.isObject(version)) {
            state.version.buildDate = version.build_date_utc;
            state.version.version = version.version;
            state.version.hash = version.hash;
            state.version.hash_long = version.hash_long;
            state.version.env = version.env;
            state.version.message = version.message;
        }
    },
    env: (state, env) => {
        state.version.env = env;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
