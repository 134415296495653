<template>
    <v-container>
        <v-row
                class="mb-6"
                justify="center"
                no-gutters
        >
            <v-col lg="4" md="6">
                <v-card :loading="loading">
                    <v-toolbar flat dense>
                        <v-toolbar-title>Login</v-toolbar-title>
                        <v-spacer/>
                        <v-icon>mdi-account-lock</v-icon>
                    </v-toolbar>
                    <v-divider/>
                    <v-card-text>
                        <v-form ref="loginForm" v-model="valid" v-on:submit.prevent="submit">
                            <v-text-field
                                    v-model="username"
                                    label="Benutzername"
                                    prepend-icon="mdi-account"
                                    required
                                    @input="$v.username.$touch()"
                                    @blur="$v.username.$touch()"
                                    :error-messages="usernameErrors"
                                    autocomplete="current-username"
                                    ref="benutzername"
                            />
                            <v-text-field
                                    v-model="password"
                                    label="Passwort"
                                    prepend-icon="mdi-lock"
                                    type="password"
                                    required
                                    @input="$v.password.$touch()"
                                    @blur="$v.password.$touch()"
                                    :error-messages="passwordErrors"
                                    autocomplete="current-password"
                                    ref="passwort"
                            />
                            <v-btn block type="submit" color="primary" :loading="loading">anmelden</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-alert class="mt-4" v-if="alert.show" :type="alert.type">{{ alert.text }}</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength} from 'vuelidate/lib/validators'
    import {AUTH_LOGIN, /*AUTH_LOGOUT, AUTH_SUCCESS*/} from "../store/actions/auth";

    export default {
        name: "Login",

        mixins: [validationMixin],

        validations: {
            username: {required, maxLength: maxLength(10)},
            password: {required},
        },

        data: () => ({
            title: 'Login',
            loading: false,
            username: '',
            password: '',
            valid: true,
            alert: {
                show: false,
                type: "error",
                text: '',
            }
        }),

        computed: {
            usernameErrors() {
                const errors = []
                if (!this.$v.username.$dirty) return errors
                !this.$v.username.maxLength && errors.push('Name must be at most 10 characters long')
                !this.$v.username.required && errors.push('Benutzername ist erforderlich.')
                return errors
            },
            passwordErrors() {
                const errors = []
                if (!this.$v.password.$dirty) return errors
                !this.$v.password.required && errors.push('Passwort ist erforderlich.')
                return errors
            },
        },

        methods: {
            submit() {
                this.$v.$touch()
                if (this.passwordErrors.length === 0 && this.usernameErrors.length === 0) {
                    this.login()
                }
            },
            login() {
                this.loading = true;
                const { username, password } = this;
                this.$store.dispatch(AUTH_LOGIN, { username, password }).then(() => {
                    this.$router.push('/')
                }).catch(error => {
                    window.console.log(error)
                    if (typeof error !== "undefined") {
                        if (typeof error.response !== "undefined") {
                            window.console.log(error.response)
                            if (typeof error.response.data !== "undefined") {
                                if(error.response.data.Details) {
                                    this.alert.text = error.response.data.Details
                                } else {
                                    this.alert.text = error.response.data.Message
                                }
                                this.alert.show = true
                            }
                        }
                    }
                }).finally(() => this.loading = false);
            },
            focusBenutzernameInput() {
                this.$refs.benutzername.focus();
            },
        },

        created: function() {
            if (this.$store.getters.isAuthenticated) {
                this.$router.push('/')
            }
        },

        mounted: function() {
            this.focusBenutzernameInput();
        },

    }
</script>

<style scoped>

</style>
