import SLApi from './SLApi'
import Urls from "./Urls";

export default {
    login (username, password) {
        let params = {
            UserName: username,
            Password: password
        };
        if (process.env.VUE_APP_SL_APPKEY) {
            params.AppKey = process.env.VUE_APP_SL_APPKEY
        }
        return SLApi.client.post(SLApi.encodeUrl(Urls.Account.login()), params)
    },
    logout () {
        return SLApi.client.post(SLApi.encodeUrl(Urls.Account.logout()))
    },
    setAuthHeader(tokenType, token) {
        SLApi.client.defaults.headers.common['Authorization'] = tokenType + ' ' + token;
    },
    removeAuthHeader() {
        delete SLApi.client.defaults.headers.common['Authorization'];
    }
}
