import { render, staticRenderFns } from "./LagerplatzAuswahl.vue?vue&type=template&id=6209475c&scoped=true&"
import script from "./LagerplatzAuswahl.vue?vue&type=script&lang=js&"
export * from "./LagerplatzAuswahl.vue?vue&type=script&lang=js&"
import style0 from "./LagerplatzAuswahl.vue?vue&type=style&index=0&id=6209475c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6209475c",
  null
  
)

export default component.exports