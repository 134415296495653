import TypeChecks from "../../utils/typecheck";

/**
 * Progressbar
 */

const state = {
    active: false,
    value: 0
};

const getters = {
    isActive: state => state.active,
    value: state => state.value,
};

const mutations = {
    start: state => {
        state.active = true;
    },
    done: (state) => {
        state.active = false;
        state.value = 0;
    },
    increase:(state, increment) => {
        if (TypeChecks.isUndefined(increment)) {
            state.value++;
        } else {
            state.value = increment;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
};
