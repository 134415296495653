import { ERROR_SHOW, ERROR_HIDE } from "../actions/ApiClientError";

const state = {
    show: false,
    text: '',
    details: '',
    url: '',
    statusCode: null
};

const getters = {
    getShowError: state => state.show,
    getMessage: state => state.text,
    getDetails: state => state.details,
    getUrl: state => state.url
};

const mutations = {
    [ERROR_SHOW]: (state, payload) => {
        state.show = true;
        state.text = payload.message;
        if (typeof payload.details !== 'undefined') {
            state.details = payload.details;
        }
        state.url = payload.url;
    },
    [ERROR_HIDE]: (state) => {
        state.show = false;
        state.text = '';
        state.details = '';
        state.url = '';
        state.statusCode = null;
    },
};

export default {
    state,
    getters,
    mutations
};
