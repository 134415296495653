<template>
    <div>
        <v-text-field
            dense
            ref="storagePlaceField"
            :loading="storagePlaceField.loading"
            v-model="storagePlaceField.value"
            placeholder="Lagerplatz"
            @change="findLagerplatzByDimensions"
            prepend-icon="mdi-home-city-outline"
            :append-icon="appendIcon"
            @click:append="clearStoragePlaceField"
            append-outer-icon="mdi-dots-horizontal"
            @click:append-outer="openStoragePlacesDialog"
            :success="storagePlaceField.success"
            :success-messages="storagePlaceField.successMsg"
            :error-messages="storagePlaceField.errorMsg"
            :error="storagePlaceField.error"
            :hide-details=false
            :error-count=1
            :readonly="storagePlaceField.readOnly"
            :disabled="storagePlaceField.disabled"
            outlined
        />

        <v-dialog v-model="storagePlacesDialog.show" transition="dialog-bottom-transition" max-width="600">
            <DataCard title="Lagerplatz auswählen" @closeDialog="closeStoragePlacesDialog" :loading="storagePlacesDialog.loading">
                <template v-slot:text>
                    <v-text-field
                        v-if="storagePlacesDialog.show"
                        autofocus
                        ref="searchField"
                        class="ml-auto"
                        :loading="storagePlacesDialog.searchField.loading"
                        v-model="storagePlacesDialog.searchField.value"
                        placeholder="Lagerplatz suchen"
                        v-debounce:400ms="searchStoragePlace"
                        append-icon="mdi-close"
                        @click:append="clearStoragePlaceSearch"
                        :messages="storagePlacesDialog.searchField.messages"
                        :success="storagePlacesDialog.searchField.success"
                        :success-messages="storagePlacesDialog.searchField.successMsg"
                        :error-messages="storagePlacesDialog.searchField.errorMsg"
                        :error="storagePlacesDialog.searchField.error"
                        :hide-details=false
                        :error-count=1
                        :readonly="storagePlacesDialog.searchField.readOnly"
                        :disabled="storagePlacesDialog.searchField.disabled"
                        outlined
                    />
                    <v-divider/>
                    <!--<v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>-->
                    <v-list>
                        <v-list-item v-for="(storagePlace) in storagePlaces.slice(0, 50)" :key="storagePlace.Id"
                                     @click="setStoragePlaceFromDialog(storagePlace)">
                            <v-list-item-icon>Platz {{ getStoragePlaceDimensions(storagePlace) }}</v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ storagePlace.Name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </template>
            </DataCard>
        </v-dialog>
    </div>
</template>

<script>
import DataCard from "@/components/cards/DataCard";
import TypeChecks from "@/utils/typecheck";
import LagerService from "@/services/LagerService";
import moment from "moment";

export default {
    name: "LagerplatzAuswahl",
    components: {
        DataCard
    },
    props: {
        warehouse: {
            type: String
        },
        appendIcon: {
            type: String,
            default: 'mdi-close'
        }
    },
    watch: {
        warehouse: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getWarehouseStoragePlaces()
                this.clearStoragePlaceField()
            }
        },
    },
    data: () => {
        return {
            storagePlace: {},
            storagePlaces: {},
            storagePlacesAll: {},
            storagePlaceField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            storagePlacesDialog: {
                show: false,
                loading: false,
                searchField: {
                    value: '',
                    success: false,
                    error: false,
                    successMsg: [],
                    errorMsg: [],
                    messages: [],
                    loading: false,
                    readOnly: false,
                    disabled: false,
                },
                searchTime: null
            },
        }
    },
    computed: {
        hasStoragePlaces: function () {
            return !TypeChecks.isEmptyObject(this.storagePlaces)
        },
    },
    methods: {
        focusField() {
            this.$refs.storagePlaceField.focus()
        },
        getStoragePlaceDimensions(storagePlace) {
            return LagerService.getStoragePlaceDimensions(storagePlace)
        },
        getWarehouseStoragePlaces() {
            LagerService.getWarehouseStoragePLaces(this.warehouse).then(response => {
                this.storagePlaces = this.storagePlacesAll = response.data
                if (this.hasStoragePlaces && this.storagePlaceField.value === '') {
                    this.focusField()
                }
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.errorMessage = error.response;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        openStoragePlacesDialog() {
            this.storagePlacesDialog.show = true
        },
        closeStoragePlacesDialog() {
            this.storagePlacesDialog.show = false
            this.$emit('closeStoragePlacesDialog');
        },
        setStoragePlaceFromDialog(storagePlace) {
            this.findLagerplatzById(storagePlace.Id)
            this.clearStoragePlaceSearch()
            this.closeStoragePlacesDialog()
        },
        clearStoragePlaceSearch() {
            this.storagePlacesDialog.searchField.value = ''
            this.storagePlacesDialog.searchField.success = false
            this.storagePlacesDialog.searchField.successMsg = []
            this.storagePlacesDialog.searchField.error = false;
            this.storagePlacesDialog.searchField.errorMsg = []
            this.storagePlaces = this.storagePlacesAll
        },
        findLagerplatzByDimensions(storagePlaceDimensions) {
            this.storagePlace = {}
            this.storagePlaceField.errorMsg = this.storagePlaceField.successMsg = ''
            let dimensions = storagePlaceDimensions.split(',')
            if (!dimensions[1]) {
                dimensions[1] = 0
            }
            if (!dimensions[2]) {
                dimensions[2] = 0
            }
            let storagePlace = this.storagePlacesAll.find(function (storagePlace) {
                let bReturn = true
                if (parseInt(dimensions[0]) !== storagePlace.Dimension1) {
                    bReturn = false
                }
                if (parseInt(dimensions[1]) !== storagePlace.Dimension2) {
                    bReturn = false
                }
                if (parseInt(dimensions[2]) !== storagePlace.Dimension3) {
                    bReturn = false
                }
                return bReturn
            });
            if (TypeChecks.isUndefined(storagePlace)) {
                this.storagePlaceField.errorMsg = `Lagerplatz ${storagePlaceDimensions} nicht gefunden!`;
                //this.openStoragePlacesDialog()
            } else {
                this.setStoragePlace(storagePlace)
            }
        },
        findLagerplatzById(storagePlaceId) {
            this.storagePlace = {}
            this.storagePlaceField.errorMsg = this.storagePlaceField.successMsg = ''
            let storagePlace = this.storagePlacesAll.find(function (storagePlace) {
                return storagePlace.Id === parseInt(storagePlaceId)
            });
            if (TypeChecks.isUndefined(storagePlace)) {
                this.storagePlaceField.errorMsg = `Lagerplatz Id ${storagePlaceId} nicht gefunden!`;
                //this.openStoragePlacesDialog()
            } else {
                this.setStoragePlace(storagePlace)
            }
        },
        setStoragePlace(storagePlace) {
            this.storagePlace = storagePlace
            this.storagePlaceField.value = this.getStoragePlaceDimensions(storagePlace)
            this.storagePlaceField.successMsg = storagePlace.Name;
            this.storagePlaceField.success = true
            this.$emit('setStoragePlace', storagePlace);
        },
        clearStoragePlaceField() {
            this.storagePlaceField.value = ''
            this.storagePlaceField.errorMsg = this.storagePlaceField.successMsg = ''
            this.storagePlaceField.success = false
        },
        searchStoragePlace() {
            let searchStart = moment();
            this.storagePlacesDialog.loading = true;
            this.storagePlacesDialog.searchField.loading = true;
            this.storagePlacesDialog.searchField.success = false;
            this.resetSearchFieldErrors()

            let self = this;
            this.storagePlaces = this.storagePlacesAll.filter(function (storagePlace) {
                let searchValue = self.storagePlacesDialog.searchField.value.toLowerCase()
                return storagePlace.Id.toString().toLowerCase().includes(searchValue)
                    || (storagePlace.Name !== null && storagePlace.Name.toLowerCase().includes(searchValue))
                    || storagePlace.Warehouse.toLowerCase().includes(searchValue)
            });

            this.storagePlacesDialog.searchField.loading = false;

            if (this.storagePlacesDialog.searchField.value !== '') {
                if (this.storagePlaces.length > 0) {
                    this.storagePlacesDialog.searchField.success = true;
                    if (this.storagePlaces.length === 1) {
                        //this.$router.push({name: 'PackzettelDetail', params: {belegnummer: this.belege[0].Number}})
                    }
                } else {
                    this.storagePlacesDialog.searchTime = (moment()).diff(searchStart, 'milliseconds')
                    this.storagePlacesDialog.searchField.errorMsg.push('Keinen Lagerplätze gefunden');
                    // Api Abfrage
                    //this.getPackzettel(this.searchField.value);
                }
            }
            this.storagePlacesDialog.searchTime = (moment()).diff(searchStart, 'milliseconds')
            this.storagePlacesDialog.loading = false;
        },
        resetSearchFieldErrors() {
            this.storagePlacesDialog.searchField.error = false;
            this.storagePlacesDialog.searchField.errorMsg = '';
        }
    },
    created() {
        this.getWarehouseStoragePlaces()
    },
    mounted() {
    },
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}
</style>
