/**
 * API Url's
 */

import TypeChecks from "../utils/typecheck";

const Urls = {
    Account: {
        login: () => `/Login`,
        logout: () => `/Logout`,
    },
    Info: () => `/Info`,
    User: {
        users: () => `/Users`,
        current: () => `${Urls.User.users()}/Current`,
    },
    Tenant: {
        tenants: () => `/Tenants`,
        tenant: (tenantName) => `${Urls.Tenant.tenants()}/${tenantName}`,
    },
    Document: {
        selectDocuments: () => `/Macros/SelectBelege`,
        documents: (belegtyp, belegnummer) => {
            if (TypeChecks.isString(belegtyp) && TypeChecks.isString(belegnummer)) {
                return `/Documents/${belegtyp}${belegnummer}`;
            } else if (TypeChecks.isString(belegtyp)) {
                return `/Documents?SelectDocumentKindFlag=${belegtyp}&SelectDocumentState=3&OrderBy=MetaData.CreationDate!`;
            } else {
                return `/Documents/`;
            }
        },
        positionen: (belegtyp, belegnummer) => `/Documents/${belegtyp}${belegnummer}/Positions?documentPositionListFilter.items=0`,
        belegP: () => `/Macros/GetBelegP`,
        successors: (belegtyp, belegnummer) => `/Documents/${belegtyp}${belegnummer}/Successors`,
        print: (belegtyp, belegnummer) => `/Documents/${belegtyp}${belegnummer}/Print`,
        articleItem: (belegtyp, belegnummer) => `/Documents/${belegtyp}${belegnummer}/ArticleItem`,
        storePositions: (belegtyp, belegnummer) => `/Documents/${belegtyp}${belegnummer}/Positions/Store`,
    },
    Packzettel: {
        positionen: (belegnummer) => `/Documents/P${belegnummer}/Positions?documentPositionListFilter.items=0`,
        updatePosition: (belegnummer, documentPositionIdentifier) => `/Documents/P${belegnummer}/Positions/${documentPositionIdentifier}`,
    },
    Article: {
        articles: (filter) => {
            if (typeof filter !== 'undefined') {
                return `/Articles?Items=100&filter=${filter}`
            } else {
                return `/Articles`
            }
        },
        article: (artikelnummer) => `/Articles/${artikelnummer}`,
        stock: () => `/Macros/GetFreierLagerbestand`,
        stockWithStoragePlaces: () => `/Macros/GetFreierLagerbestandMitLagerplaetze`,
        stockWarehouseWithStoragePlaces: () => `/Macros/GetFreierLagerbestandFuerLagerMitLagerplaetze`,
    },
    Druckvorlagen: {
        vorlagen: () => `/Macros/SelectDruckvorlagen`,
        belegtyp: () => `/Macros/SelectDruckvorlagenBelegtyp`
    },
    ExtraTable: {
        lagerAppLog: () => `/ExtraTable/XLagerAppLog`,
        lagerAppLogByUserAndDate: () => `/Macros/GetXLagerAppLogByUserAndDate`,
        lagerAppLogByUserAndDateAggregated: () => `/Macros/GetXLagerAppLogByUserAndDateAggregated`,
        lagerAppLogScanCounts: () => `/Macros/GetXLagerAppLogScanCount`
    },
    Inventory: {
        inventories: (status) => {
            if (!TypeChecks.isUndefined(status)) {
                return `/Inventories?inventoryListFilter.filter=Status EQ '${status}'&inventoryListFilter.items=0`
            } else {
                return `/Inventories?inventoryListFilter.items=0`
            }
        },
        warehouses: (number) => {
            return `/Inventories/${number}/Warehouses?inventoryListFilter.items=0`
        },
        articles: (number, warehouse) => {
            return `/Inventories/${number}/Warehouses/${warehouse}/Articles?inventoryListFilter.items=0`
        },
        article: (number, warehouse, article) => {
            return `/Inventories/${number}/Warehouses/${warehouse}/Articles/${article}/`
        },
    },
    ManualStorages: {
        manualStorages: () => `/ManualStorages`,
        tagesBeleg: () => `/Macros/GetManuelleLagerungTagesbeleg`,
        positionStore: (manualStorageNumber) => `/ManualStorages/${manualStorageNumber}/Positions/Store`
    },
    Warehouses: {
        warehouses: (number, filter) => {
            if (TypeChecks.isUndefined(number) || number === null) {
                if (TypeChecks.isUndefined(filter)) {
                    return `/Warehouses`
                } else {
                    return `/Warehouses?filter=${filter}`
                }
            } else {
                return `/Warehouses/${number}`
            }
        },
        storagePlaces: (warehouseNumber) => {
            return `/Warehouses/${warehouseNumber}/StoragePlaces?listFilter.items=0`
        }
    }
};

export default Urls
