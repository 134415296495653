import store from "./store"

/**
 * Menu Items
 *
 * @type {[{icon: string, show: (function(): boolean), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}, {submenu: [{icon: string, show: (function(): boolean|*), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}], icon: string, show: (function(): boolean|*), description: string, text: string, page: string}, {submenu: [{icon: string, show: (function(): boolean|*), text: string, page: string}, {icon: string, show: (function(): boolean|*), text: string, page: string}], icon: string, show: (function(): boolean|*), text: string, page: string}, null]}
 */
const Menu = [
    {
        text: 'Login',
        icon: 'mdi-account-lock',
        page: '/login',
        show: function () {
            return !store.getters.isAuthenticated;
        }
    },
    {
        text: 'Home',
        icon: 'mdi-home',
        page: '/',
        show: function () {
            return store.getters.isAuthenticated;
        }
    },
    {
        text: 'Artikel',
        icon: 'mdi-cube',
        page: '/artikel',
        description: 'Artikel verwalten',
        show: function () {
            return store.getters.isAuthenticated;
        },
        submenu: [
            {
                text: 'Lagerbestand anzeigen',
                icon: 'mdi-information',
                page: '/artikel/lagerbestand',
                show: function () {
                    return store.getters.isAuthenticated;
                },
            },
            {
                text: 'Permanente Inventur',
                icon: 'mdi-clipboard-check',
                page: '/artikel/permanentinventur',
                show: function () {
                    return store.getters.isAuthenticated;
                },
            },
            {
                text: 'Umlagerung',
                icon: 'mdi-clipboard-flow',
                page: '/artikel/umlagerung',
                show: function () {
                    return store.getters.isAuthenticated;
                },
            },
            {
                text: 'Lager-App Log',
                icon: 'mdi-clipboard-text',
                page: '/artikel/lagerapplog',
                show: function () {
                    return store.getters.isAuthenticated;
                },
            },
        ]
    },
    {
        text: 'Inventur',
        icon: 'mdi-clipboard-text',
        page: '/inventur',
        show: function () {
            return store.getters.isAuthenticated;
        },
    },
    {
        text: 'Info',
        icon: 'mdi-information',
        page: '/info',
        show: function () {
            return true;
        }
    },
];

export default Menu;
