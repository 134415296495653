import SLApi from './SLApi'
import Urls from "./Urls";

export default {
    get() {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Inventory.inventories()))
    },
    getOpen() {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Inventory.inventories('1')))
    },
    getWarehouses(number) {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Inventory.warehouses(number)))
    },
    getArticles(number, warehouse) {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Inventory.articles(number, SLApi.encodeUrlComponent(warehouse))))
    },
    addArticleQuantity(number, warehouse, articleNumber, quantity, serialNumber, expirationDate, priceQuantity) {
        let params = {
            Quantity: quantity,
            Serialnumber: serialNumber,
            //ExpirationDate: "2021-03-02T13:16:02.3753548+01:00",
            ExpirationDate: expirationDate,
            PriceQuantity: priceQuantity,
        }
        return SLApi.client.put(SLApi.encodeUrl(Urls.Inventory.article(number, SLApi.encodeUrlComponent(warehouse), SLApi.encodeUrlComponent(articleNumber))), params)
    },
    setArticleQuantity(number, warehouse, article, quantity, serialNumber, expirationDate, priceQuantity) {
        let params = {
            Quantity: quantity,
            Serialnumber: serialNumber,
            //ExpirationDate: "2021-03-02T13:16:02.3753548+01:00",
            ExpirationDate: expirationDate,
            PriceQuantity: priceQuantity,
        }
        return SLApi.client.post(SLApi.encodeUrl(Urls.Inventory.article(number, SLApi.encodeUrlComponent(warehouse), SLApi.encodeUrlComponent(article))), params)
    },
    KindFlags: {
        1: 'Vollständig (alle Artikel in allen Lägern)',
        2: 'Ausgewählte Artikel in allen Lägern',
        3: 'Alle Artikel in ausgewählten Lägern',
        4: 'Ausgewählte Artikel in ausgewählten Lägern',
    }
}
