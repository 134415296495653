<template>
    <div>
        <KeyboardEvents v-on:keyup="keyboardEvent"></KeyboardEvents>
        <DataCard v-bind:title="getTitle" @goBack="goBack" v-bind:loading="loading">
            <template v-slot:text>
                <LagerplatzAuswahl
                    ref="LagerplatzAuswahl"
                    v-if="hasWarehouseStoragePlaces"
                    v-bind:warehouse="lager"
                    @setStoragePlace="setStoragePlace"
                />
                <v-text-field
                    ref="artikelField"
                    :loading="artikelField.loading"
                    v-model="artikelField.value"
                    placeholder="Artikel erfassen"
                    @change="scanArtikel"
                    prepend-icon="mdi-barcode-scan"
                    append-icon="mdi-close"
                    @click:append="clearArtikelField"
                    append-outer-icon="mdi-dots-horizontal"
                    @click:append-outer="openArticlesDialog"
                    :success="artikelField.success"
                    :success-messages="artikelField.successMsg"
                    :error-messages="artikelField.errorMsg"
                    :error="artikelField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="artikelField.readOnly"
                    :disabled="artikelField.disabled"
                    outlined
                />
                <DataTransferChips
                    text-left-chip="Art. erfasst"
                    text-right-chip="Art. gesendet"
                    :value-left-chip="articlesScanned.length"
                    :value-right-chip="articlesSend().length"
                />
                <v-divider/>
                <v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>
                <div class="article-data" v-if="isArticleData">
                    <h4>{{ articleData.ArticleName || articleData.Name }}</h4>
                    {{ articleData.AdditionalDescription }}

                    <div class="menge">Menge in {{ articleData.QuantityUnit }}</div>
                    <MengeInput @confirmQuantity="confirmArticleQuantity" v-bind:focus="mengeInputFocus"
                                :suffix="articleData.QuantityUnit"/>
                </div>
                <v-alert type="info" v-if="isLastArticle" outlined>
                    <h3 class="headline">
                        zuletzt erfasst
                    </h3>
                    {{ lastArticle.ArticleNumber || lastArticle.Number }}<br>
                    {{ lastArticle.ArticleName || lastArticle.Name }}<br>
                    {{ lastArticle.AdditionalDescription }}<br>
                    {{ lastArticle.ManufacturerArticleNumber }}<br>
                    {{ lastArticle._Quantity }} {{ lastArticle.QuantityUnit }}
                </v-alert>
            </template>
        </DataCard>

        <ArtikelAuswahlDialog
            ref="ArtikelAuswahlDialog"
            @setArtikelFromDialog="setArtikelFromDialog"
        />
    </div>
</template>

<script>
import InventurService from "../services/InventurService";
import DataCard from "../components/cards/DataCard";
import KeyboardEvents from "../components/app/KeyboardEvents";
import TypeChecks from "../utils/typecheck";
import DataTransferChips from "../components/Chips/DataTransferChips";
import MengeInput from "../components/forms/MengeInput";
import LagerService from "@/services/LagerService";
import LagerplatzAuswahl from "@/components/Lager/LagerplatzAuswahl";
import ArtikelAuswahlDialog from "@/components/Artikel/ArtikelAuswahlDialog";
import ArtikelService from "@/services/ArtikelService";

export default {
    name: "InventurLager",
    components: {
        LagerplatzAuswahl,
        MengeInput,
        DataTransferChips,
        DataCard,
        KeyboardEvents,
        ArtikelAuswahlDialog
    },
    props: {
        inventur: {
            type: String,
            required: true
        },
        lager: {
            type: String,
            required: true
        },
    },
    data: () => {
        return {
            articles: [],
            articlesAll: [],
            articlesScanned: [],
            articleData: {},
            lastArticle: {},
            artikelField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            mengeInputFocus: false,
            alert: {
                show: false,
                msg: ''
            },
            loading: false,
            sendingWorkerQueue: {
                interval: null,
                sending: false
            },
            warehouse: {},
            storagePlace: {}
        }
    },
    watch: {},
    computed: {
        getTitle: function () {
            return 'Inventur ' + this.inventur + ' / Lager ' + this.lager
        },
        isArticleData: function () {
            return !TypeChecks.isEmptyObject(this.articleData)
        },
        isLastArticle: function () {
            return !TypeChecks.isEmptyObject(this.lastArticle)
        },
        isOnline: function () {
            return this.$store.getters['connection/isOnline']
        },
        hasWarehouseStoragePlaces: function () {
            return LagerService.hatLagerplaetze(this.warehouse)
        }
    },
    methods: {
        goBack() {
            if (this.isAllArticlesSend()) {
                this.$router.push({name: 'Inventur', params: {inventur: this.inventur}})
            } else {
                this.showAlertNotAllSend()
            }
        },
        showAlertNotAllSend() {
            this.alert.show = true
            this.alert.msg = 'Nicht alle Artikel wurden gesendet!'
        },
        articlesSend() {
            return this.articlesScanned.filter(article => {
                    return article.send === true
                }
            )
        },
        isAllArticlesSend() {
            let articlesSend = this.articlesSend()
            return (this.articlesScanned.length === articlesSend.length)
        },
        keyboardEvent(e) {
            // pattern for only chars and numbers
            let pattern = /^[a-zA-Z0-9-_]$/;
            // on ENTER
            if (e.keyCode === 13) {
                this.scanArtikel();
                this.artikelField.value = '';
            } else if (pattern.test(e.key)) {
                this.artikelField.value += e.key;
            }
        },
        scanArtikel() {
            let artikelnummer = this.artikelField.value;
            if (artikelnummer) {
                this.resetArtikelInputErrors();
                this.articleData = {}
                this.mengeInputFocus = false

                this.loading = true
                let filter = `(Number EQ '${artikelnummer}' OR EuropeanArticleNumber EQ '${artikelnummer}') AND IsWarehouseArtikel EQ 1`;
                ArtikelService.getArtikel(filter).then(response => {
                    if (response.data.length === 1) {
                        this.articleData = response.data[0]
                        this.artikelField.success = true
                        this.$nextTick(() => {
                            this.mengeInputFocus = true
                        })
                        //this.artikelField.successMsg = `Der Artikel '${this.artikelField.value}' wurde erfasst!`;
                        this.lastArticle = {}
                    } else if (response.data.length === 0) {
                        this.artikelField.errorMsg = `Der Artikel wurde nicht gefunden oder ist nicht Lager aktiv!`
                    } else {
                        this.artikelField.errorMsg = `Es wurden mehrere Artikel gefunden!`
                    }
                    //this.clearArtikelField()
                }).catch(error => {
                    console.log(error.response)
                    if (typeof error.response !== "undefined") {
                        this.artikelField.error = true;
                        this.artikelField.errorMsg = `Der Artikel '${this.artikelField.value}' wurde nicht gefunden!`;
                    } else {
                        this.errorMessage = error.toString();
                    }
                    this.clearArtikelField()
                    this.focusArtikelField()
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        confirmArticleQuantity(quantity) {
            if (!TypeChecks.isEmptyObject(this.articleData)) {
                this.articleData['_Quantity'] = quantity
                this.articleData['send'] = false;
                this.articlesScanned.push(JSON.parse(JSON.stringify(this.articleData)))
                this.setLastScannedArticle()
                this.mengeInputFocus = false
            }
        },
        async sendArticle(article) {
            return new Promise((resolve, reject) => {
                let warehouse = this.lager
                if (!TypeChecks.isEmptyObject(this.storagePlace)) {
                    warehouse += ',' + this.$refs.LagerplatzAuswahl.getStoragePlaceDimensions(this.storagePlace)
                }
                let articleNumber = article.ArticleNumber || article.Number
                InventurService.addArticleQuantity(this.inventur, warehouse, articleNumber, article._Quantity).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                }).finally(() => {

                })
            })
        },
        startSendingWorkerQueue() {
            this.sendingWorkerQueue.interval = setInterval(() => {
                if (this.isOnline) {
                    this.sendingWorkerQueue.sending = true
                    let self = this
                    this.articlesScanned.forEach((article, key) => {
                        if (!article.send) {
                            this.sendArticle(article).then(function () {
                                self.articlesScanned[key].send = true
                            }).catch(error => {
                                console.log(error.response)
                                if (typeof error.response !== "undefined") {
                                    this.alert.msg = error.response.data.Details.ResponseDetails;
                                } else {
                                    this.alert.msg = error.toString();
                                }
                                this.alert.show = true
                                delete self.articlesScanned[key]
                            })
                        }
                    })
                }
                this.sendingWorkerQueue.sending = false
            }, 2000);
        },
        setLastScannedArticle() {
            this.lastArticle = this.articleData
            this.articleData = {}
            this.resetArtikelInputErrors()
            this.artikelField.value = null
            this.artikelField.success = false;
            this.focusArtikelField()
        },
        openArticlesDialog() {
            this.$refs.ArtikelAuswahlDialog.openDialog()
        },
        setArtikelFromDialog(article) {
            this.artikelField.value = article.Number
            this.scanArtikel()
        },
        resetArtikelInputErrors() {
            this.artikelField.error = false;
            this.artikelField.errorMsg = '';
        },
        focusArtikelField() {
            //this.$refs.searchField.focus();
            // prevent keyboard from opening
            this.artikelField.readOnly = true;
            const self = this;
            setTimeout(function () {
                self.artikelField.readOnly = false;
                self.$refs.artikelField.focus();
            }, 500);
        },
        clearArtikelField() {
            this.artikelField.value = ''
        },
        getWarehouse() {
            LagerService.getWarehouse(this.lager).then(response => {
                this.warehouse = response.data
                if (!this.hasWarehouseStoragePlaces) {
                    this.focusArtikelField()
                }
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.errorMessage = error.response;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        setStoragePlace(storagePlace) {
            this.storagePlace = storagePlace
            this.focusArtikelField()
        }
    },
    created() {
        this.getWarehouse()
    },
    mounted() {
        this.startSendingWorkerQueue()
    },
    beforeMount() {
        let self = this
        window.addEventListener("beforeunload", event => {
            if (this.isAllArticlesSend()) return
            event.preventDefault()
            // Chrome requires returnValue to be set.
            event.returnValue = ""
            self.showAlertNotAllSend(event)
        })
    },
    beforeDestroy() {
        window.clearInterval(this.sendingWorkerQueue.interval);
    }
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}

.article-data {
    margin: 10px 0;
}

.article-data .menge {
    margin: 10px 0;
    font-weight: bold;
}
</style>
