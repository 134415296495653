import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi', //'mdiSvg' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    theme: {
        light: true,
        themes: {
            dark: {
                primary: colors.indigo.darken1,
                secondary: colors.indigo.lighten5,
                accent: colors.indigo.accent4, // #3F51B5
            },
            light: {
                primary: colors.grey.darken3,
                secondary: colors.grey.lighten2,
                accent: colors.grey.accent4, // #3F51B5
                textWhite: '#ffffff',
                textBlack: '#000000',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                progressLoader: colors.lightBlue.accent2,
                //toolbarHeader: '#3c3c3c',
            },
        },
    },
};

export default new Vuetify(opts)
