/**
 * Window Timers
 */
const state = {
    timer: []
};

const getters = {

};

const actions = {
    add({ commit }, timer) {
        commit('add', timer)
    },
    remove({ commit }, timerToRemove) {
        commit('remove', timerToRemove)
    },
    removeAll({ commit }) {
        commit('removeAll')
    },
};

const mutations = {
    add(state, timer) {
        state.timer.push({
            ident: timer.ident,
            timer: timer.number,
        })
    },
    remove(state, ident, timerToRemove) {
        state.timer = state.timer.filter(
            timer => timer.ident !== timerToRemove.ident
        )
    },
    removeAll(state) {
        state.timer.forEach(function (timer) {
            clearInterval(timer.timer);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
