import Vue from "vue";
import moment from "moment";

import TypeChecks from "../utils/typecheck";

/**
 * Date-Time filter
 */
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

Vue.filter('formatDateTime', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
    }
});

Vue.filter('formatTime', function (value) {
    if (value) {
        return moment(String(value)).format('HH:mm:ss')
    }
});

Vue.filter('formatDateTimeUTC', function (value) {
    moment.locale('de-DE')
    let date = new Date(String(value));

    return moment(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())).format('DD.MM.YYYY HH:mm:ss')
});

/**
 * Number filter
 */
Vue.filter('formatNumber', function (value, digits) {
    if (TypeChecks.isUndefined(digits)) {
        digits = 2
    }
    if (TypeChecks.isNumber(value)) {
        let formatter = new Intl.NumberFormat('de-DE', {
            maximumFractionDigits: digits,
            minimumFractionDigits: digits
        });

        return formatter.format(value)
    }
});

Vue.filter('formatEuro', function (value) {
    if (value && TypeChecks.isNumber(value)) {
        return value.toFixed(2).toLocaleString() + ' €'
    }
});

Vue.filter('formatCurrency', function (value) {
    if (TypeChecks.isNumber(value)) {
        let formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });

        return formatter.format(value)
    }
});

/**
 * Prettify Json String
 */
Vue.filter('prettyJson', function (value) {
    if (value && TypeChecks.isJsonString(value)) {
        return JSON.stringify(JSON.parse(value), null, 2);
    }
});

/**
 *
 */
Vue.filter('replaceNewlineToBR', function (value) {
    if (value) {
        return value.replace(/\n/g, '<br>');
    }
});
