import axios from 'axios'
import Vue from "vue";
import store from "../store"
import router from "../router"
import {ERROR_SHOW, /*ERROR_HIDE*/} from "../store/actions/ApiClientError";
import VueCookies from "vue-cookies";
import {AUTH_LOGOUT} from "../store/actions/auth";
import ConfigService from "@/services/ConfigService";

Vue.use(VueCookies);

// https Agent
const https = require('https');

// Trust self signed ssl certs
let rejectUnauthorized = !ConfigService.getSelectLineApiUrlTrustSSL()

/**
 * API Client Instance
 *
 * @type {AxiosInstance}
 */
const clientInstance = axios.create({
    baseURL: ConfigService.getSelectLineApiUrl(),
    //withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-App-Name': process.env.VUE_APP_TITLE,
        'X-App-Version': process.env.VUE_APP_VERSION,
    },
    httpsAgent: new https.Agent({
        rejectUnauthorized: rejectUnauthorized
    })
});

/**
 * LoginId from cookie
 */
let token = Vue.$cookies.get('user-token');
if (token !== null) {
    clientInstance.defaults.headers.common['Authorization'] = 'LoginId ' + token;
}

/**
 * Add a response interceptor for checking of API errors
 */
clientInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    let errorMessage = '';
    let errorDetails = '';
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        /*
        console.log(error.response)
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        */

        if ([405, 406].indexOf(error.response.status) > -1) {
            if (typeof error.response.data !== 'undefined') {
                errorMessage = error.response.data.Message;
                errorDetails += '\nStatusCode: ' + error.response.data.StatusCode;
                errorDetails += '\nResponseCode: ' + error.response.data.ResponseCode;
                errorDetails += '\nDetails: ' + JSON.stringify(error.response.data.Details);
            }
        }

        // Login User Error
        if ([401, 403].indexOf(error.response.status) > -1) {
            store.commit(AUTH_LOGOUT);
            // you can also redirect to /login if needed !
            if ((router.currentRoute.name !== 'Login')) {
                router.push({name: 'Login'})
            }
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error);
        errorMessage = error.message;
    } else {
        // Something happened in setting up the request that triggered an Error

        //console.log('Error', error.message);
        errorMessage = error.message;

    }
    //console.log(error.config);

    // Show error message
    if (errorMessage !== '') {
        store.commit(ERROR_SHOW, ({
            'url': error.config.baseURL + '/' + error.config.url,
            'message': errorMessage,
            'details': errorDetails
        }));
    }
    return Promise.reject(error);
});

/**
 * encodeUrl for SelectLine API
 */
const encodeUrl = (url) => {
    return url;
};

/**
 * encodeUrlComponent for SelectLine API
 */
const encodeUrlComponent = (component) => {
    return encodeURIComponent(component).replace('%', '~');
};

export default {
    client: clientInstance,
    encodeUrl: encodeUrl,
    encodeUrlComponent: encodeUrlComponent
};
