<template>
    <div class="status-bullets">
        <v-offline
            online-class="online"
            offline-class="offline"
            class="network-status status-bullet"
            @detected-condition="handleConnectivityChange">
            <div @click="toggleShowNetworkStatus" @mouseover="showShowNetworkStatus"
                 @mouseleave="hideShowNetworkStatus" style="height: 100%; width: 100%"></div>
        </v-offline>

        <div
            class="backendmanager-status status-bullet"
            :class="isBackendManagerRunning ? 'running' : 'not-running'"
        >
            <div @click="toggleShowBackendManagerStatus" @mouseover="showShowBackendManagerStatus"
                 @mouseleave="hideShowBackendManagerStatus" style="height: 100%; width: 100%"></div>
        </div>

        <v-tooltip v-model="showNetworkStatus" bottom>
            <template v-slot:activator="{ on }">
                <div v-on="on"></div>
            </template>
            <span>Netzwerk Status: {{ networkStatus }}</span>
        </v-tooltip>

        <v-tooltip v-model="showBackendManagerStatus" bottom>
            <template v-slot:activator="{ on }">
                <div v-on="on"></div>
            </template>
            <span>SelectLine Backend Manager Status: {{ isBackendManagerRunning ? 'running' : 'not-running' }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { VOffline } from 'v-offline';
import { mapGetters } from "vuex";

export default {
    name: "StatusBullets",

    components: {
        VOffline,
    },

    data: () => {
        return {
            onLine: null,
            onlineSlot: 'online',
            offlineSlot: 'offline',
            showNetworkStatus: false,
            showNetworkStatusTimer: false,
            showBackendManagerStatus: false,
            showBackendManagerStatusTimer: false,
        }
    },

    computed: {
        networkStatus: function () {
            return (this.onLine ? 'online' : 'offline')
        },
        ...mapGetters('info', {
            isBackendManagerRunning: 'isBackendManagerRunning',
        }),
    },

    watch: {
        showNetworkStatus: function (newVal) {
            const self = this;
            if (newVal === true) {
                self.showNetworkStatusTimer = setTimeout(function () {
                    self.showNetworkStatus = false
                }, 3000)
            }
        },
        showBackendManagerStatus: function (newVal) {
            const self = this;
            if (newVal === true) {
                self.showBackendManagerStatusTimer = setTimeout(function () {
                    self.showBackendManagerStatus = false
                }, 3000)
            }
        },
        showStellplaetzeStatus: function (newVal) {
            const self = this;
            if (newVal === true) {
                self.showStellplaetzeStatusTimer = setTimeout(function () {
                    self.showStellplaetzeStatus = false
                }, 3000)
            }
        }
    },

    methods: {
        handleConnectivityChange(e) {
            this.onLine = e;
        },
        toggleShowNetworkStatus() {
            clearTimeout(this.showNetworkStatusTimer);
            this.showNetworkStatus = !this.showNetworkStatus;
        },
        hideShowNetworkStatus() {
            clearTimeout(this.showNetworkStatusTimer);
            this.showNetworkStatus = false;
        },
        showShowNetworkStatus() {
            clearTimeout(this.showNetworkStatusTimer);
            this.showNetworkStatus = true;
        },
        toggleShowBackendManagerStatus() {
            clearTimeout(this.showBackendManagerStatusTimer);
            this.showBackendManagerStatus = !this.showBackendManagerStatus;
        },
        hideShowBackendManagerStatus() {
            clearTimeout(this.showBackendManagerStatusTimer);
            this.showBackendManagerStatus = false;
        },
        showShowBackendManagerStatus() {
            clearTimeout(this.showBackendManagerStatusTimer);
            this.showBackendManagerStatus = true;
        },
    },

    mounted() {
        // Online Status des Browser beim Laden ermitteln
        this.onLine = navigator.onLine;
    }
}
</script>

<style scoped>
.status-bullets {
    display: flex;
}

.status-bullet {
    height: 20px;
    width: 20px;
    border-radius: 20px;
}

.status-bullet > div {
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.status-bullet + .status-bullet {
    margin-left: 5px
}

.not-running,
.offline {
    background-color: #c3732e;
    background-image: linear-gradient(315deg, #c3732e, #ec2742 74%);
}

.running,
.online {
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
}

@keyframes blink {
    to {
        background-color: #00b712;
        background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
    }
}
</style>
