<template>
    <v-progress-linear
            absolute top
            :value="value"
            :active="isActive"
            :color="color !== '' ? color : defaultColorProgressBar"
            :indeterminate="indeterminate"
            :height="height"
    />
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        name: "Progressbar",
        props: {
            color: {
                Type: String,
                default: ''
            },
            indeterminate: {
                Type: Boolean,
                default: true
            },
            height: {
                Type: Number,
                default: 2
            },
        },
        data: function () {
            return {
                defaultColorProgressBar: this.$vuetify.theme.themes.light.progressLoader
            }
        },
        computed: {
            ...mapGetters('progressbar', {
                isActive: 'isActive',
                value: 'value'
            })
        },
    }
</script>

<style scoped>
    .v-progress-linear {
        z-index: 10;
    }
</style>
