import SLApi from './SLApi'
import Urls from "./Urls";

export default {
    getArtikel (filter) {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Article.articles(filter)))
    },
    get: (artikelnummer) => {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Article.article(artikelnummer)))
    },
    getFreierLagerbestand: (artikelnummer) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.Article.stock()), [
            {'Name': 'Artikelnummer', 'Value': artikelnummer}
        ])
    },
    getFreierLagerbestandMitLagerplaetze: (artikelnummer) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.Article.stockWithStoragePlaces()), [
            {'Name': 'Artikelnummer', 'Value': artikelnummer}
        ])
    },
    getFreierLagerbestandFuerLagerMitLagerplaetze: (artikelnummer, lager) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.Article.stockWarehouseWithStoragePlaces()), [
            {'Name': 'Artikelnummer', 'Value': artikelnummer},
            {'Name': 'Lager', 'Value': lager}
        ])
    },
}
