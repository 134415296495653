<template>
    <div>

        <DataCard v-bind:title="'Artikel Bestand'" @goBack="goBack" v-bind:loading="loading">
            <v-overlay absolute :value="loading"/>
            <template v-slot:text>
                <v-text-field
                    ref="artikelField"
                    :loading="artikelField.loading"
                    v-model="artikelField.value"
                    placeholder="Artikelnummer / EAN"
                    @change="scanArtikel"
                    prepend-icon="mdi-barcode-scan"
                    append-icon="mdi-close"
                    @click:append="clearArtikelField"
                    append-outer-icon="mdi-dots-horizontal"
                    @click:append-outer="openArticlesDialog"
                    :success="artikelField.success"
                    :success-messages="artikelField.successMsg"
                    :error-messages="artikelField.errorMsg"
                    :error="artikelField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="artikelField.readOnly"
                    :disabled="artikelField.disabled"
                    outlined
                />
                <v-divider/>
                <v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>
                <div class="article-data" v-if="isArticleData">
                    <h3>{{ articleData.Name }}</h3>
                    {{ articleData.AdditionalDescription }}<br>
                    <strong>Mengeneinheit: {{ articleData.QuantityUnit }}</strong>

                    <v-list class="bestand">
                        <v-alert v-if="alertKeinBestand" type="warning" color="orange">Keine Bestände vorhanden!
                        </v-alert>
                        <v-list-item v-for="(stockData, index) in articleStockData" :key="index">
                            <v-list-item-content class="align-end">
                                <v-list-item-title><h3 class="pb-2">Lager: {{ stockData.Lager }} -
                                    {{ stockData.LagerBezeichnung }}</h3></v-list-item-title>
                                <div class="d-flex item">
                                    <div class="mr-auto">Bestand:</div>
                                    <div class="align-end">{{ stockData.Bestand | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Gepackt:</div>
                                    <div class="align-end">{{ stockData.Gepackt | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Reserviert:</div>
                                    <div class="align-end">{{ stockData.Reserviert | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Bestellt:</div>
                                    <div class="align-end">{{ stockData.Bestellt | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Verfügbar:</div>
                                    <div class="align-end">{{ stockData.Verfuegbar | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div v-if="parseInt(stockData.Lagertyp) > 0">
                                    <v-btn block color="secondary" class="mt-2" @click="showLagerplaetze(stockData)">
                                        Lagerplätze anzeigen
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </DataCard>

        <ArtikelAuswahlDialog
            ref="ArtikelAuswahlDialog"
            @setArtikelFromDialog="setArtikelFromDialog"
        />

        <v-dialog v-model="storagePlacesDialog.show" transition="dialog-bottom-transition" max-width="600">
            <DataCard :title="storagePlacesDialog.title" @closeDialog="closeStoragePlacesDialog"
                      :loading="storagePlacesDialog.loading">
                <template v-slot:text>
                    <v-list>
                        <v-list-item v-for="(storagePlace, index) in storagePlacesDialog.storagePlaces" :key="index">
                            <v-list-item-content class="align-end">
                                <v-list-item-title><h3 class="pb-2">Lagerplatz: {{ getStoragePlaceDimensions(storagePlace) }} -
                                    {{ storagePlace.LagerplatzBezeichnung }}</h3></v-list-item-title>
                                <div class="d-flex item">
                                    <div class="mr-auto">Bestand:</div>
                                    <div class="align-end">{{ storagePlace.Bestand | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Gepackt:</div>
                                    <div class="align-end">{{ storagePlace.Gepackt | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <div class="d-flex item">
                                    <div class="mr-auto">Verfügbar:</div>
                                    <div class="align-end">{{ storagePlace.Verfuegbar | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </template>
            </DataCard>
        </v-dialog>
    </div>
</template>

<script>
import DataCard from "../components/cards/DataCard";
//import KeyboardEvents from "@/components/app/KeyboardEvents";
import ArtikelService from "@/services/ArtikelService";
import TypeChecks from "@/utils/typecheck";
import MacroService from "@/services/MacroService";
import ArtikelAuswahlDialog from "@/components/Artikel/ArtikelAuswahlDialog";
import LagerService from "@/services/LagerService";

export default {
    name: "ArtikelBestandAnzeige",
    components: {
        ArtikelAuswahlDialog,
        DataCard,
        //KeyboardEvents,
    },
    data: () => {
        return {
            articles: [],
            articleData: {},
            articleStockData: [],
            artikelField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            alert: {
                show: false,
                msg: ''
            },
            loading: false,
            alertKeinBestand: false,
            storagePlacesDialog: {
                show: false,
                loading: false,
                title: '',
                storagePlaces: []
            },
        }
    },
    computed: {
        isArticleData: function () {
            return !TypeChecks.isEmptyObject(this.articleData)
        },
        isOnline: function () {
            return this.$store.getters['connection/isOnline']
        }
    },
    watch: {},
    methods: {
        goBack() {
            this.$router.push({name: 'Artikel'})
        },
        scanArtikel() {
            let artikelnummer = this.artikelField.value;
            if (artikelnummer) {
                this.resetArtikelInputErrors();
                this.articleData = {}
                this.articleStockData = {}
                this.alertKeinBestand = false
                this.mengeInputFocus = false
                this.getArticleData()
            }
        },
        resetArtikelInputErrors() {
            this.artikelField.error = false;
            this.artikelField.errorMsg = '';
        },
        focusArtikelField() {
            //this.$refs.searchField.focus();
            // prevent keyboard from opening
            this.artikelField.readOnly = true;
            const self = this;
            setTimeout(function () {
                self.artikelField.readOnly = false;
                self.$refs.artikelField.focus();
            }, 500);
        },
        clearArtikelField() {
            this.artikelField.value = ''
        },
        openArticlesDialog() {
            this.$refs.ArtikelAuswahlDialog.openDialog()
        },
        setArtikelFromDialog(article) {
            this.artikelField.value = article.Number
            this.scanArtikel()
        },
        getArticleData() {
            this.loading = true
            let searchValue = this.artikelField.value
            let filter = `(Number EQ '${searchValue}' OR EuropeanArticleNumber EQ '${searchValue}') AND IsWarehouseArtikel EQ 1`;
            ArtikelService.getArtikel(filter).then(response => {
                if (response.data.length === 1) {
                    this.focusArtikelField()
                    this.articleData = response.data[0]
                    this.artikelField.successMsg = `Artikel: ${this.articleData.Number}`
                    this.getArticleStockData(this.articleData.Number)
                } else if (response.data.length === 0) {
                    this.artikelField.errorMsg = `Der Artikel wurde nicht gefunden oder ist nicht Lager aktiv!`
                } else {
                    this.artikelField.errorMsg = `Es wurden mehrere Artikel gefunden!`
                }
                this.clearArtikelField()
            }).catch(error => {
                console.log(error.response)
                if (typeof error.response !== "undefined") {
                    this.artikelField.error = true;
                    this.artikelField.errorMsg = `Der Artikel '${this.artikelField.value}' wurde nicht gefunden!`;
                } else {
                    this.errorMessage = error.toString();
                }
                this.clearArtikelField()
            }).finally(() => {
                this.loading = false
            })
        },
        getArticleStockData(articleNumber) {
            this.loading = true
            ArtikelService.getFreierLagerbestand(articleNumber).then(response => {
                this.articleStockData = MacroService.combineSelectData(response.data)
                if (this.articleStockData.length > 0) {
                    this.alertKeinBestand = false
                } else {
                    this.alertKeinBestand = true
                }
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.alert.show = true;
                    this.alert.msg = `Keine Bestandsinformationen für Artikel '${articleNumber}' verfügbar!`;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        showLagerplaetze(stockData) {
            this.storagePlacesDialog.loading = true
            this.storagePlacesDialog.title = 'Lager ' + stockData.Lager + ' - ' + stockData.LagerBezeichnung
            this.storagePlacesDialog.show = true
            ArtikelService.getFreierLagerbestandFuerLagerMitLagerplaetze(this.articleData.Number, stockData.Lager).then(response => {
                this.storagePlacesDialog.storagePlaces = MacroService.combineSelectData(response.data)
                if (this.storagePlacesDialog.storagePlaces.length > 0) {
                    this.alertKeinBestand = false
                } else {
                    this.alertKeinBestand = true
                }
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.alert.show = true;
                    this.alert.msg = `Keine Lagerplatz Bestandsinformationen für Artikel '${this.articleData.Number}' im Lager '${stockData.Lager}' verfügbar!`;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.storagePlacesDialog.loading = false
            })
        },
        closeStoragePlacesDialog() {
            this.storagePlacesDialog = {
                show: false,
                loading: false,
                title: '',
                storagePlaces: []
            }
        },
        getStoragePlaceDimensions(stockData) {
            // bereits storagePlace Objekt?
            let storagePlace = stockData
            if (stockData.LagerplatzDimension1) {
                storagePlace = {
                    'Dimension1': stockData.LagerplatzDimension1,
                    'Dimension2': stockData.LagerplatzDimension2,
                    'Dimension3': stockData.LagerplatzDimension3
                }
            }
            return LagerService.getStoragePlaceDimensions(storagePlace)
        },
        // Create callback function to receive barcode when the scanner is already done
        onBarcodeScanned (barcode) {
            console.log(barcode)
            // do something...
        },
        // Reset to the last barcode before hitting enter (whatever anything in the input box)
        resetBarcode () {
            let barcode = this.$barcodeScanner.getPreviousCode()
            // do something...
            alert(barcode)
        }
    },
    mounted() {
        //this.focusArtikelField()
    },
    created() {
        // Add barcode scan listener and pass the callback function
        this.$barcodeScanner.init(this.onBarcodeScanned)
    },
    destroyed () {
        // Remove listener when component is destroyed
        //this.$barcodeScanner.destroy()
    },
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}

.bestand .item {
    padding: 2px 25px 2px 30px;
    font-size: 1rem;
}
</style>
