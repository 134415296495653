import SLApi from "./SLApi";
import Urls from "./Urls";
import store from "@/store";
import TypeChecks from "@/utils/typecheck";

export default {
    addLagerAppLog: (articleNumber, scannedAt) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.ExtraTable.lagerAppLog()), {
                Fields: {
                    '_ARTIKELNUMMER': articleNumber,
                    '_GESCANNTAM': scannedAt
                }
            }
        )
    },

    getLagerAppLogScanCounts: (date) => {
        const user = store.getters.getProfile.WawiUserToken
        return SLApi.client.post(SLApi.encodeUrl(Urls.ExtraTable.lagerAppLogScanCounts()), [
                {'Name': 'User', 'Value': user},
                {'Name': 'Datum', 'Value': date}
            ]
        )
    },

    getLagerAppLogByDay: (scannedAt) => {
        const user = store.getters.getProfile.WawiUserToken
        return SLApi.client.post(SLApi.encodeUrl(Urls.ExtraTable.lagerAppLogByUserAndDate()), [
                {'Name': 'User', 'Value': user},
                {'Name': 'Datum', 'Value': scannedAt}
            ]
        )
    },

    getLagerAppLogByUserAndDateAggregated: () => {
        const user = store.getters.getProfile.WawiUserToken
        return SLApi.client.post(SLApi.encodeUrl(Urls.ExtraTable.lagerAppLogByUserAndDateAggregated()), [
                {'Name': 'User', 'Value': user},
            ]
        )
    },

    getWarehouses: (filter) => {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Warehouses.warehouses(null, filter)))
    },

    getWarehouse: (number) => {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Warehouses.warehouses(number)))
    },

    getWarehouseStoragePLaces: (number) => {
        return SLApi.client.get(SLApi.encodeUrl(Urls.Warehouses.storagePlaces(number)))
    },

    getStoragePlaceDimensions(storagePlace) {
        let dimensions = []
        if (storagePlace.Dimension1 > 0) {
            dimensions.push(storagePlace.Dimension1)
        }
        if (storagePlace.Dimension2 > 0) {
            dimensions.push(storagePlace.Dimension2)
        }
        if (storagePlace.Dimension3 > 0) {
            dimensions.push(storagePlace.Dimension3)
        }
        return dimensions.join(',')
    },

    hatLagerplaetze(warehouse) {
        // Version 20.3
        if (!TypeChecks.isUndefined(warehouse.KindFlag) && parseInt(warehouse.KindFlag) > 0) {
            return true
        }
        // Version 21.1
        if (!TypeChecks.isUndefined(warehouse.StorageType) && warehouse.StorageType > 0) {
            return true
        }

        return false
    }
}
