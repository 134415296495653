<template>
    <v-container grid-list>
        <v-row dense>
            <v-col cols="12">
                <DataCard v-bind:title="appTitle" @goBack="goBack">
                    <template v-slot:text>
                        <v-list class="info-list">
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Version</v-list-item-title>
                                    <v-list-item-subtitle>{{ appVersion }} / Git #{{ getAppGitVersion }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Build Date</v-list-item-title>
                                    <v-list-item-subtitle>{{ getAppBuildDate }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Environment</v-list-item-title>
                                    <v-list-item-subtitle>{{ getNodeEnv }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>App-Key</v-list-item-title>
                                    <v-list-item-subtitle>{{ getAppKey }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Entwicklung</v-list-item-title>
                                    <v-list-item-subtitle><a href="https://www.viebrock.de" target="_blank">Viebrock
                                        DatenService GmbH</a></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                </DataCard>
            </v-col>
            <v-col cols="12">
                <DataCard v-bind:title="'SelectLine API'">
                    <template v-slot:text>
                        <v-list class="info-list">
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Url</v-list-item-title>
                                    <v-list-item-subtitle>{{ getSLAPIUrl }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-for="(item, index) in info"
                                :key="index"
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{ index }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="pt-3 mt-3"/>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-menu right top>
                                    <template v-slot:activator="{ on }">
                                        <v-list-item-content v-on="on">
                                            <v-list-item-title>Mandant</v-list-item-title>
                                            <v-list-item-subtitle>{{ getMandant }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                    <v-list class="overflow-y-auto" max-height="400">
                                        <v-subheader>Mandanten</v-subheader>
                                        <v-list-item
                                            v-for="mandant in getMandanten"
                                            :key="mandant"
                                            @click="changeMandant(mandant)"
                                        >
                                            <v-list-item-title>{{ mandant }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </v-list-item>
                            <v-list-item
                                two-line
                                class="pa-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>Version</v-list-item-title>
                                    <v-list-item-subtitle>{{ getMandantVersion }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                </DataCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";
import TypeChecks from "@/utils/typecheck";
import DataCard from "@/components/cards/DataCard";
import {USER_REQUEST} from "@/store/actions/user";
import {LOADER_HIDE, LOADER_SHOW} from "@/store/actions/loader";
import UserService from "@/services/UserService";
import ConfigService from "@/services/ConfigService";

export default {
    name: 'Info',
    components: {
        DataCard,
    },

    data() {
        return {}
    },
    watch: {},
    computed: {
        getMandant: function () {
            return this.$store.getters.getProfile.TenantDatabaseName
        },
        getMandantVersion: function () {
            return this.$store.getters.getProfile.TenantDatabaseVersion
        },
        getMandanten: function () {
            return this.$store.getters.getProfile.AllowedTenantList

            // const tenants = Array.from(this.$store.getters.getProfile.AllowedTenantList);
            // return tenants.sort(function(a, b){return a - b});

        },
        appVersion: function () {
            return process.env.VUE_APP_VERSION
        },
        appTitle: function () {
            return process.env.VUE_APP_TITLE
        },
        ...mapState('info', {
            info: state => state.info
        }),
        getNodeEnv: () => {
            return process.env.NODE_ENV;
        },
        getAppGitVersion: () => {
            return process.env.VERSION;
        },
        getAppBuildDate: () => {
            let appBuildTimestamp = document.body.dataset.buildTimestampUtc;
            return (TypeChecks.isUndefined(appBuildTimestamp) ? 'na' : moment(appBuildTimestamp).format('DD.MM.YYYY HH:mm:ss'));
        },
        getSLAPIUrl() {
            return ConfigService.getSelectLineApiUrl()
        },
        getAppKey() {
            return process.env.VUE_APP_SL_APPKEY
        }
    },
    methods: {
        goBack() {
            this.$router.push({name: 'Home'})
        },
        changeMandant: function (mandant) {
            this.$store.commit(LOADER_SHOW);
            UserService.changeTenant(mandant)
                .then(response => {
                    window.console.log(response);
                    this.$store.dispatch(USER_REQUEST);
                })
                .catch(error => {
                    window.console.log(error.response);
                    this.$store.commit(LOADER_HIDE);
                })
                .finally(() => {

                });
        },
        // Create callback function to receive barcode when the scanner is already done
        onBarcodeScanned (barcode) {
            console.log(barcode)
            // do something...
        },
        // Reset to the last barcode before hitting enter (whatever anything in the input box)
        resetBarcode () {
            let barcode = this.$barcodeScanner.getPreviousCode()
            // do something...
            alert(barcode)
        }
    },
    created() {
        // Add barcode scan listener and pass the callback function
        this.$barcodeScanner.init(this.onBarcodeScanned)
    },
    destroyed () {
        // Remove listener when component is destroyed
        this.$barcodeScanner.destroy()
    },
    mounted() {
    }
}
</script>
<style scoped>
.info-list {
    padding: 0;
}

.info-list .v-list-item {
    min-height: auto;
}

.info-list .v-list-item .v-list-item__content {
    padding: 8px 0;
}

.info-list hr {

}
</style>
