<template>
    <v-card class="mx-auto">
        <v-card-title>
            {{ getTitle }}
            <v-spacer/>
            <v-btn icon @click="goBack">
                <v-icon>mdi-reply</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="getDescription !== undefined">{{ getDescription }}</v-card-subtitle>
        <v-divider/>
        <v-card-text>
            <v-list nav>
                <v-list-item
                    v-for="(submenu, index) in this.submenu"
                    v-bind:key="index"
                    :to="submenu.page"
                >
                    <v-list-item-action>
                        <v-icon>{{ submenu.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ submenu.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import Menu from '../../menu';
import router from '../../router'

export default {
    name: "Submenu",
    data: () => ({
        submenu: Array
    }),
    computed: {
        getTitle: function () {
            let activeMenu = this.getActiveMenu()
            return activeMenu.text;
        },
        getDescription: function () {
            let activeMenu = this.getActiveMenu()
            return activeMenu.description;
        },
    },
    created() {
        this.submenu = this.getSubmenu()
    },
    methods: {
        getActiveMenu() {
            return Menu.find(link => {
                return link.page === router.currentRoute.path;
            });
        },
        getSubmenu() {
            let activeMenu = this.getActiveMenu();

            let submenu = [];
            if (typeof activeMenu.submenu !== 'undefined') {
                submenu = activeMenu.submenu.filter(link => {
                    return link;
                });
            }

            return submenu
        },
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>

</style>
