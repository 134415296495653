<template>
    <div>
        <!--<Title>Inventur</Title>-->
        <DataCard v-bind:title="'Lager auswählen'" v-bind:data="warehouses" @goBack="goBack" v-bind:loading="loading">
            <v-overlay absolute :value="loading" />
            <template v-slot:text>
                <v-text-field
                    ref="searchField"
                    :loading="searchField.loading"
                    v-model="searchField.value"
                    placeholder="Lager"
                    @keyup="searchWarehouse"
                    append-icon="mdi-close"
                    @click:append="clearSearch"
                    :success="searchField.success"
                    :success-messages="searchField.successMsg"
                    :error-messages="searchField.errorMsg"
                    :error="searchField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="searchField.readOnly"
                    :disabled="searchField.disabled"
                    outlined
                />
                <v-divider/>
                <v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>
                <v-list>
                    <v-list-item v-for="(warehouse, index) in warehouses" :key="index"
                                 @click="goToDetails(warehouse.Warehouse)">
                        <v-list-item-icon>{{ warehouse.Warehouse }}</v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ warehouse.WarehouseName }}</v-list-item-title>
                            <v-list-item-subtitle>
                                Zählliste: {{ warehouse.CheckList }}<br>
                                Artikel gesamt: {{ warehouse.ArticleCount }}<br>
                                Artikel erfasst: {{ warehouse.ArticleAcquired }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </DataCard>
    </div>
</template>

<script>
import InventurService from "../services/InventurService";
import DataCard from "../components/cards/DataCard";

export default {
    name: "Inventur",
    components: {
        DataCard
    },
    props: {
        inventur: {
            type: String,
            required: true
        },
    },
    data: () => {
        return {
            warehouses: [],
            warehousesAll: [],
            searchField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            alert: {
                show: false,
                msg: ''
            },
            loading: false
        }
    },
    watch: {
        'searchField.value': function (newVal) {
            if (newVal === '') {
                this.searchField.success = false;
                this.searchField.error = false;
            }
            this.searchWarehouse(newVal)
        },
        'belege': function () {
            this.cntBelege = this.belege.length;
        },
        'loading': function () {
            if (this.loading === true) {
                this.loadingCardColor = this.$vuetify.theme.themes.light.progressLoader;
            } else {
                this.loadingCardColor = ''
            }
        },
    },
    computed: {},
    methods: {
        goBack() {
            this.$router.push({name: 'Inventuren'})
        },
        getWarehouses() {
            this.loading = true
            InventurService.getWarehouses(this.inventur).then(response => {
                this.warehouses = this.warehousesAll = response.data
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.errorMessage = error.response;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        goToDetails(number) {
            this.$router.push({name: 'InventurLager', params: {inventur: this.inventur, lager: number}})
        },
        focusSearchField() {
            // prevent keyboard from opening
            this.searchField.readOnly = true;
            const self = this;
            setTimeout(function () {
                self.searchField.readOnly = false;
                self.$refs.searchField.focus();
            }, 500);
        },
        clearSearch() {
            this.searchField.value = '';
            this.warehouses = this.warehousesAll;
        },
        searchWarehouse() {
            this.searchField.loading = true;
            this.searchField.success = false;
            this.resetSearchFieldErrors()

            let self = this;
            this.warehouses = this.warehousesAll.filter(function (warehouse) {
                let regex = new RegExp(self.searchField.value, 'gi');
                return warehouse.Warehouse.match(regex);
            });

            this.searchField.loading = false;

            if (this.searchField.value !== '') {
                if (this.warehouses.length > 0) {
                    this.searchField.success = true;
                    if (this.warehouses.length === 1) {
                        //this.$router.push({name: 'PackzettelDetail', params: {belegnummer: this.belege[0].Number}})
                    }
                } else {
                    this.searchField.error = true;
                    this.alert.show = true;
                    this.alert.msg = 'Kein Lager gefunden'
                    // Api Abfrage
                    //this.getPackzettel(this.searchField.value);
                }
            }
        },
        resetSearchFieldErrors() {
            this.searchField.error = false;
            this.searchField.errorMsg = '';
            this.alert.show = false;
            this.alert.msg = ''
        }
    },
    created() {
        this.getWarehouses()
    },
    mounted() {
        this.focusSearchField()
    }
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 15px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}
</style>
