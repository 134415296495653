<template>
    <div>
        <v-bottom-sheet v-model="updateExists" persistent>
            <v-sheet class="update-alert-sheet">
                <v-alert class="mb-0" type="info" prominent>
                    <v-row align="center">
                        <v-col class="grow">
                            Eine neue Version der App ist verfügbar!
                        </v-col>
                        <v-col class="shrink">
                            <v-btn
                                color="white"
                                @click="refreshApp"
                                large
                            >
                                Update
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="configUpdateExists" persistent>
            <v-sheet class="update-alert-sheet">
                <v-alert class="mb-0" type="info" prominent>
                    <v-row align="center">
                        <v-col class="grow">
                            Eine neue Konfiguration der App ist verfügbar!
                        </v-col>
                        <v-col class="shrink">
                            <v-btn
                                color="white"
                                @click="refreshConfig"
                                large
                            >
                                <v-icon>mdi-cog-refresh</v-icon> Update
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="configAlertExists" persistent>
            <v-sheet>
                <v-alert class="mb-0" type="error" prominent>
                    <v-row align="center">
                        <v-col class="grow">
                            Die App-Konfiguration konnte nicht geladen werden!
                        </v-col>
                        <v-col class="shrink">
                            <v-btn
                                color="white"
                                @click="refreshConfig"
                                icon
                                class="elevation-2"
                                outlined
                            >
                                <v-icon>mdi-cog-refresh</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import {LOADER_SHOW} from "../../store/actions/loader";

export default {
    name: "AppUpdateMessage",

    data: () => ({
        refreshing: false,
        registration: null,
        updateDetails: null,
        updateExists: false,
        configUpdateExists: false,
        configAlertExists: false,
    }),

    methods: {
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        downloadUpdate(e) {
            this.updateDetails = e.detail;
        },
        refreshApp() {
            this.$store.commit(LOADER_SHOW);
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage('skipWaiting');
        },
        showRefreshConfig() {
            this.configUpdateExists = true;
        },
        refreshConfig() {
            this.$store.commit(LOADER_SHOW);
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
        },
        showAlertConfig() {
            this.configAlertExists = true
        }
    },

    created: function () {
        // Check for updated version of app
        document.addEventListener(
            'swUpdated', this.showRefreshUI, {once: true}
        );
        document.addEventListener(
            'downloadUpdate', this.downloadUpdate, {once: true}
        );
        document.addEventListener(
            'configUpdated', this.showRefreshConfig, {once: true}
        );
        document.addEventListener(
            'configFetchError', this.showAlertConfig, {once: true}
        );
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) return;
                    this.refreshing = true;
                    window.location.reload();
                }
            );
        }
    }
}
</script>

<style scoped>
.update-alert-sheet {
    background-color: #2196f3;
}

.update-alert-sheet .v-alert {
    max-width: 500px;
    margin: 0 auto;
}

.update-alert-sheet .v-alert .v-btn {
    width: 120px;
    font-weight: bold;
    color: #2979FF;
}
</style>
