import SLApi from "./SLApi";
import Urls from "./Urls";
import store from "../store";

export default {
    get: () => {
        return SLApi.client.get(SLApi.encodeUrl(Urls.ManualStorages.manualStorages()))
    },
    getTodaysUserDocument: () => {
        const user = store.getters.getProfile.WawiUserToken
        return SLApi.client.post(SLApi.encodeUrl(Urls.ManualStorages.tagesBeleg()), [
            {'Name': 'AngelegtVon', 'Value': user}
        ])
    },
    create: () => {
        const EmployeeNumber = store.getters.getProfile.EmployeeNumber
        return SLApi.client.post(SLApi.encodeUrl(Urls.ManualStorages.manualStorages()), {
            EmployeeNumber: EmployeeNumber
        })
    },
    createTodaysUserDocument: () => {
        const EmployeeNumber = store.getters.getProfile.EmployeeNumber
        const DisplayName = store.getters.getProfile.DisplayName
        return SLApi.client.post(SLApi.encodeUrl(Urls.ManualStorages.manualStorages()), {
            EmployeeNumber: EmployeeNumber,
            BusinessPartner: {
                Company: 'Tagesbeleg Mitarbeiter',
                LastName: DisplayName,
            }
        })
    },
    putArticle: (manualStorageNumber, quantity, articleNumber, warehouse, serialNumber) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.ManualStorages.positionStore(manualStorageNumber)), [
            {
                "Quantity": quantity,
                "ArticleNumber": articleNumber,
                "Warehouse": warehouse,
                "SerialNumber": serialNumber,
            }
        ])
    },
    putUmlagerung: (manualStorageNumber, quantity, articleNumber, warehouseQuelle, warehouseZiel, serialNumber) => {
        return SLApi.client.post(SLApi.encodeUrl(Urls.ManualStorages.positionStore(manualStorageNumber)), [
            {
                "Quantity": quantity,
                "ArticleNumber": articleNumber,
                "Warehouse": warehouseQuelle,
                "TargetWarehouse": warehouseZiel,
                "SerialNumber": serialNumber,
            }
        ])
    }
}
