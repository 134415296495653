<template>
    <div>
        <KeyboardEvents v-on:keyup="keyboardEvent"></KeyboardEvents>
        <DataCard v-bind:title="'Umlagerung'" @goBack="goBack" v-bind:loading="loading">
            <v-overlay absolute :value="loading"/>
            <template v-slot:text>
                <v-text-field
                    ref="artikelField"
                    :loading="artikelField.loading"
                    v-model="artikelField.value"
                    placeholder="Artikelnummer / EAN"
                    @change="scanArtikel"
                    prepend-icon="mdi-barcode-scan"
                    append-icon="mdi-close"
                    @click:append="clearArtikelField"
                    append-outer-icon="mdi-dots-horizontal"
                    @click:append-outer="openArticlesDialog"
                    :success="artikelField.success"
                    :success-messages="artikelField.successMsg"
                    :error-messages="artikelField.errorMsg"
                    :error="artikelField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="artikelField.readOnly"
                    :disabled="artikelField.disabled"
                    outlined
                />
                <v-row>
                    <v-col cols="7"><div class="font-italic">Belegnummer: {{ getTagesBelegAnzeige }}</div></v-col>
                    <v-col cols="5" class="text-right"></v-col>
                </v-row>
                <v-divider/>
                <v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>
                <div class="article-data" v-if="isArticleData">
                    <h3>{{ articleData.Name }}</h3>
                    {{ articleData.AdditionalDescription }}

                    <v-list class="bestand">
                        <h3>Bestände:</h3>
                        <v-alert v-if="alertKeinBestand" type="warning" color="orange">Keine Bestände vorhanden!</v-alert>
                        <v-list-item v-for="(stockData, index) in articleStockData" :key="index" @click="openArticleStockDialog(index)">
                            <v-list-item-content>
                                <v-list-item-title>Lager: {{ stockData.Lager }} - {{
                                        stockData.LagerBezeichnung
                                    }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="parseInt(stockData.Lagertyp) > 0">Lagerplatz: {{ getStoragePlaceDimensions(stockData) }} - {{
                                        stockData.LagerplatzBezeichnung
                                    }}
                                </v-list-item-subtitle>
                                <div class="d-flex item">
                                    <div class="mr-auto">Bestand:</div>
                                    <div class="align-end">{{ stockData.Bestand | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon
                                    @click="openArticleStockDialog(index)"
                                >
                                    mdi-arrow-right-bold
                                </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>
                <v-alert type="info" v-if="isLastArticle" outlined>
                    <h3 class="headline">
                        zuletzt erfasst
                    </h3>
                    <div class="mb-2 artikel-daten">
                        {{ lastArticle.ArticleNumber || lastArticle.Number }}<br>
                        {{ lastArticle.ArticleName || lastArticle.Name }}<br>
                        {{ lastArticle.AdditionalDescription }}
                        <!--{{ lastArticle.ManufacturerArticleNumber }}<br>-->
                    </div>
                    <div class="mb-2">
                        <strong>Quell-Lager:</strong><br>{{ lastArticle.StockData.Quelle.Lager }} - {{ lastArticle.StockData.Quelle.LagerBezeichnung }}<br>
                        <div v-if="parseInt(lastArticle.StockData.Quelle.Lagertyp) > 0">Lagerplatz: {{ getStoragePlaceDimensions(lastArticle.StockData.Quelle) }} - {{ lastArticle.StockData.Quelle.LagerplatzBezeichnung }}</div>
                    </div>
                    <div class="mb-2">
                        <strong>Ziel-Lager:</strong><br>{{ lastArticle.StockData.Ziel.Warehouse.Number }} - {{ lastArticle.StockData.Ziel.Warehouse.Name }}<br>
                        <div v-if="hatLagerplaetze(lastArticle.StockData.Ziel.Warehouse)">Lagerplatz: {{ getStoragePlaceDimensions(lastArticle.StockData.Ziel.StoragePlace) }} - {{ lastArticle.StockData.Ziel.StoragePlace.Name }}</div>
                    </div>
                    <strong>Menge: {{ lastArticle.StockData.Quelle._Quantity | formatNumber }} {{ lastArticle.QuantityUnit }}</strong>
                </v-alert>
            </template>
        </DataCard>

        <ArtikelAuswahlDialog
            ref="ArtikelAuswahlDialog"
            @setArtikelFromDialog="setArtikelFromDialog"
        />

        <v-dialog v-model="articleStockDialog.show" transition="dialog-bottom-transition" max-width="600">
            <DataCard title="Umlagern"
                      @closeDialog="closeArticleStockDialog" :loading="articleStockDialog.loading">
                <template v-slot:text>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Lager: {{ getArticleStockDataByIndex.Lager }} -
                                    {{ getArticleStockDataByIndex.LagerBezeichnung }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="parseInt(getArticleStockDataByIndex.Lagertyp) > 0">Lagerplatz: {{ getStoragePlaceDimensions(getArticleStockDataByIndex) }} - {{
                                        getArticleStockDataByIndex.LagerplatzBezeichnung
                                    }}
                                </v-list-item-subtitle>
                                <div class="pt-2 pb-2 d-flex item soll-bestand">
                                    <div class="mr-auto">Bestand:</div>
                                    <div class="align-end">{{ getArticleStockDataByIndex.Bestand | formatNumber }}
                                        {{ articleData.QuantityUnit }}
                                    </div>
                                </div>
                                <v-text-field
                                    ref="lagerField"
                                    v-model="warehouseDialog.warehouse.Number"
                                    placeholder="Ziel-Lager"
                                    prepend-icon="mdi-warehouse"
                                    append-outer-icon="mdi-dots-horizontal"
                                    @click:append-outer="openWarehouseDialog"
                                    @change="setWarehouseFromInput"
                                    :success-messages="warehouseDialog.warehouse.Name"
                                    :error-messages="warehouseDialog.errorMsg"
                                    outlined
                                ></v-text-field>
                                <LagerAuswahlDialog
                                    v-bind:show="warehouseDialog.show"
                                    @setWarehouseFromDialog="setWarehouseFromDialog"
                                    @closeWarehouseDialog="closeWarehouseDialog"
                                />
                                <LagerplatzAuswahl
                                    ref="LagerplatzAuswahl"
                                    v-if="hatLagerplaetze(warehouseDialog.warehouse)"
                                    :warehouse="warehouseDialog.warehouse.Number"
                                    append-icon=""
                                    @setStoragePlace="setStoragePlace"
                                />
                                <v-alert type="error" v-model="warehouseDialog.alert.show">{{ warehouseDialog.alert.msg }}</v-alert>
                                <MengeInput
                                    ref="MengeInput"
                                    @confirmQuantity="confirmArticleQuantity"
                                    v-bind:focus="mengeInput.focus"
                                    v-bind:text-button="'Menge umlagern'"
                                    v-bind:placeholder="'Menge'"
                                    v-bind:error="mengeInput.error"
                                    v-bind:error-msg="mengeInput.errorMsg"
                                    v-bind:style-bold="false"
                                    v-bind:suffix="articleData.QuantityUnit"
                                    v-bind:loading="mengeInput.loading"
                                    v-bind:disabled="mengeInput.disabled"
                                />


                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </template>
            </DataCard>
        </v-dialog>
    </div>
</template>

<script>
import DataCard from "../components/cards/DataCard";
import KeyboardEvents from "@/components/app/KeyboardEvents";
import ArtikelAuswahlDialog from "@/components/Artikel/ArtikelAuswahlDialog";
import TypeChecks from "@/utils/typecheck";
import ArtikelService from "@/services/ArtikelService";
import MacroService from "@/services/MacroService";
import LagerService from "@/services/LagerService";
import ManuelleLagerungService from "@/services/ManuelleLagerungService";
import LagerplatzAuswahl from "@/components/Lager/LagerplatzAuswahl";
import LagerAuswahlDialog from "@/components/Lager/LagerAuswahlDialog";
import MengeInput from "@/components/forms/MengeInput";

export default {
    name: "ArtikelUmlagerung",
    components: {
        LagerplatzAuswahl,
        LagerAuswahlDialog,
        DataCard,
        KeyboardEvents,
        MengeInput,
        ArtikelAuswahlDialog,
    },
    data: () => {
        return {
            articleData: {},
            articleStockData: {},
            articlesScanned: [],
            articlesConfirmed: [],
            lastArticle: {},
            artikelField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            mengeInput: {
                focus: false,
                error: false,
                errorMsg: '',
                loading: false,
                disabled: false,
            },
            alert: {
                show: false,
                msg: ''
            },
            loading: false,
            articleStockDialog: {
                show: false,
                loading: false,
                articleStockIndex: null
            },
            tagesBeleg: '',
            warehouseDialog: {
                show: false,
                errorMsg: [],
                warehouse: {
                    Number: null,
                    Name: null
                },
                storagePlace: {},
                alert: {
                    show: false,
                    msg: ''
                },
            },
            alertKeinBestand: false
        }
    },
    computed: {
        isArticleData: function () {
            return !TypeChecks.isEmptyObject(this.articleData)
        },
        isLastArticle: function () {
            return !TypeChecks.isEmptyObject(this.lastArticle)
        },
        isArticleStockDialogStockData: function () {
            if (this.articleStockDialog.articleStockIndex === null) {
                return false
            } else {
                return !TypeChecks.isEmptyObject(this.articleStockData[this.articleStockDialog.articleStockIndex])
            }
        },
        getArticleStockDataByIndex: function () {
            if (this.isArticleStockDialogStockData) {
                return this.articleStockData[this.articleStockDialog.articleStockIndex]
            } else {
                return {}
            }
        },
        getTagesBelegAnzeige: function () {
            if (this.tagesBeleg === '') {
                return 'n.b.'
            } else {
                return this.tagesBeleg
            }
        }
    },
    watch: {},
    methods: {
        goBack() {
            this.$router.push({name: 'Artikel'})
        },
        keyboardEvent(e) {
            // pattern for only chars and numbers
            let pattern = /^[a-zA-Z0-9-_]$/;
            // on ENTER
            if (e.keyCode === 13) {
                this.scanArtikel();
                this.artikelField.value = '';
            } else if (pattern.test(e.key)) {
                this.artikelField.value += e.key;
            }
        },
        artikelFieldKeyUp(e) {
            if (e.keyCode === 13) {
                this.scanArtikel();
                this.artikelField.value = '';
            }
        },
        scanArtikel() {
            let artikelnummer = this.artikelField.value;
            if (artikelnummer) {
                this.resetArtikelInputErrors();
                this.articleData = {}
                this.articleStockData = {}
                this.alertKeinBestand = false
                this.mengeInputFocus = false
                this.getArticleData()
            }
        },
        resetArtikelInputErrors() {
            this.artikelField.error = false;
            this.artikelField.errorMsg = '';
        },
        focusArtikelField() {
            //this.$refs.searchField.focus();
            // prevent keyboard from opening
            this.artikelField.readOnly = true;
            const self = this;
            setTimeout(function () {
                self.artikelField.readOnly = false;
                self.$refs.artikelField.focus();
            }, 500);
        },
        clearArtikelField() {
            this.artikelField.value = ''
        },
        confirmArticleQuantity(quantity) {
            this.resetAlertErrors()
            // Umlagerung eines vorhandenen Bestands
            if (TypeChecks.isNumber(parseFloat(quantity))) {
                let articleStockData = this.articleStockData[this.articleStockDialog.articleStockIndex]
                articleStockData['_Quantity'] = parseFloat(quantity)
                // Menge wird auf 2 Nachkommastellen abgeschnitten
                const menge = (articleStockData._Quantity * 100).toFixed(0) / 100
                let warehouseQuelle = articleStockData.Lager
                let warehouseZiel = this.warehouseDialog.warehouse.Number
                // Lagerplätze vorhanden?
                if (articleStockData.Lagertyp > 0) {
                    warehouseQuelle += ',' + this.getStoragePlaceDimensions(articleStockData)
                }
                if (!TypeChecks.isEmptyObject(this.warehouseDialog.storagePlace)) {
                    warehouseZiel += ',' + this.getStoragePlaceDimensions(this.warehouseDialog.storagePlace)
                }
                this.sendeUmlagerung(menge, warehouseQuelle, warehouseZiel)
            } else {
                this.mengeInput.errorMsg = 'Nur Zahlen sind erlaubt!'
                this.mengeInput.error = true
            }
        },
        sendeUmlagerung(quantity, warehouseQuelle, warehouseZiel) {
            this.mengeInput.loading = true
            this.articleStockDialog.loading = true
            this.getTagesBeleg().then(tagesBeleg => {
                this.tagesBeleg = tagesBeleg
                ManuelleLagerungService.putUmlagerung(tagesBeleg, quantity, this.articleData.Number, warehouseQuelle, warehouseZiel).then(response => {
                    this.setLastScannedArticle(quantity)
                }).catch(error => {
                    this.showAlertFromError(error)
                }).finally(() => {
                    this.closeArticleStockDialog()
                    this.mengeInput.loading = false
                    this.articleStockDialog.loading = false
                })
            }).catch(error => {
                this.showAlertFromError(error)
                this.mengeInput.loading = false
                this.articleStockDialog.loading = false
            }).finally(() => {
            })
        },
        async sendArticleScanned(articleNumber, scannedAt) {
            return new Promise((resolve, reject) => {
                LagerService.addLagerAppLog(articleNumber, scannedAt).then(response => {
                    resolve(response)
                }).catch(error => {
                    this.showAlertFromError(error)
                    reject(error)
                }).finally(() => {

                })
            })
        },
        setLastScannedArticle() {
            this.lastArticle = this.articleData
            this.lastArticle.StockData = {
                Quelle: this.getArticleStockDataByIndex,
                Ziel: {
                    Warehouse: this.warehouseDialog.warehouse,
                    StoragePlace: this.warehouseDialog.storagePlace
                }
            }
            this.$refs.MengeInput.resetValue()
            this.articleData = {}
            this.articleStockDialog.articleStockData = {}
            this.articleStockDialog.articleStockIndex = null
            this.resetArtikelInputErrors()
            this.artikelField.value = null
            this.artikelField.success = false
            this.artikelField.successMsg = []
            this.focusArtikelField()
        },
        openArticlesDialog() {
            this.$refs.ArtikelAuswahlDialog.openDialog()
        },
        setArtikelFromDialog(article) {
            this.artikelField.value = article.Number
            this.scanArtikel()
        },
        openArticleStockDialog(index) {
            this.articleStockDialog.articleStockIndex = index
            this.articleStockDialog.show = true
            setTimeout(() => {
                //this.mengeInput.focus = true
                this.$refs.lagerField.focus()
            }, 200)
        },
        closeArticleStockDialog() {
            this.mengeInput.focus = false
            this.articleStockDialog.show = false
            this.articleStockDialog.articleStockData = {}
            this.$refs.MengeInput.resetValue()
        },
        getArticleData() {
            this.loading = true
            let searchValue = this.artikelField.value
            let filter = `(Number EQ '${searchValue}' OR EuropeanArticleNumber EQ '${searchValue}') AND IsWarehouseArtikel EQ 1`;
            ArtikelService.getArtikel(filter).then(response => {
                if (response.data.length === 1) {
                    this.focusArtikelField()
                    this.articleData = response.data[0]
                    this.artikelField.successMsg = `Artikel: ${this.articleData.Number}`
                    this.lastArticle = {}
                    this.getArticleStockData(this.articleData.Number)
                } else if (response.data.length === 0) {
                    this.artikelField.errorMsg = `Der Artikel wurde nicht gefunden oder ist nicht Lager aktiv!`
                } else {
                    this.artikelField.errorMsg = `Es wurden mehrere Artikel gefunden!`
                }
                this.clearArtikelField()
            }).catch(error => {
                console.log(error.response)
                if (typeof error.response !== "undefined") {
                    this.artikelField.error = true;
                    this.artikelField.errorMsg = `Der Artikel '${this.artikelField.value}' wurde nicht gefunden!`;
                } else {
                    this.errorMessage = error.toString();
                }
                this.clearArtikelField()
            }).finally(() => {
                this.loading = false
            })
        },
        getArticleStockData(articleNumber) {
            this.loading = true
            ArtikelService.getFreierLagerbestandMitLagerplaetze(articleNumber).then(response => {
                this.articleStockData = MacroService.combineSelectData(response.data)
                if (this.articleStockData.length > 0) {
                    this.alertKeinBestand = false
                } else {
                    this.alertKeinBestand = true
                }
            }).catch(error => {
                console.log(error)
                if (typeof error.response !== "undefined") {
                    this.alert.show = true;
                    this.alert.msg = `Keine Bestandsinformationen für Artikel '${articleNumber}' verfügbar!`;
                } else {
                    this.errorMessage = error.toString();
                }
            }).finally(() => {
                this.loading = false
            })
        },
        closeStoragePlacesDialog() {
            this.storagePlacesDialog = {
                show: false,
                loading: false,
                title: '',
                storagePlaces: []
            }
        },
        getTagesBeleg() {
            return new Promise((resolve, reject) => {
                ManuelleLagerungService.getTodaysUserDocument().then(response => {
                    const data = MacroService.combineSelectData(response.data)
                    let tagesBeleg = ''
                    if (data.length > 0) {
                        tagesBeleg = data[0].Belegnummer
                    }
                    if (tagesBeleg === '') {
                        // neuen Beleg anlegen
                        this.createTagesBeleg().then(belegnummer => {
                            resolve(belegnummer)
                        })
                    } else {
                        resolve(tagesBeleg)
                    }
                }).catch(error => {
                    reject(error)
                }).finally(() => {

                })
            })
        },
        createTagesBeleg() {
            return new Promise((resolve, reject) => {
                ManuelleLagerungService.createTodaysUserDocument().then(response => {
                    resolve(response.data.manualStorageNumber)
                }).catch(error => {
                    reject(error)
                }).finally(() => {

                })
            })
        },
        showAlertFromError(error, msg) {
            // msg anzeigen
            if (!TypeChecks.isUndefined(msg) && msg !== '') {
                this.alert.msg = msg
            }
            if (!TypeChecks.isUndefined(error.response)) {
                if (!TypeChecks.isUndefined(error.response.data)) {
                    if (error.response.data.Details) {
                        this.alert.msg = error.response.data.Details
                    } else {
                        this.alert.msg = error.response.data.Message
                    }
                } else {
                    this.alert.msg = error.toString();
                }
            }
            this.alert.show = true
        },
        resetAlertErrors() {
            this.alert.show = false;
            this.alert.msg = '';
        },
        openWarehouseDialog() {
            this.warehouseDialog.show = true
        },
        closeWarehouseDialog() {
            this.warehouseDialog.show = false
        },
        setWarehouseFromInput() {
            LagerService.getWarehouse(this.warehouseDialog.warehouse.Number).then(response => {
                this.warehouseDialog.errorMsg = []
                this.warehouseDialog.warehouse = response.data
                this.warehouseDialog.storagePlace = {}
                this.mengeInput.focus = true
                this.checkWarehouse()
            }).catch(error => {
                let msg = ''
                if (!TypeChecks.isUndefined(error.response)) {
                    if (!TypeChecks.isUndefined(error.response.data)) {
                        if (error.response.data.StatusCode === 'NotFound') {
                            msg = `Das Lager '${this.warehouseDialog.warehouse.Number}' konnte nicht gefunden werden`
                        } else {
                            msg = error.response.data.Message
                        }
                    } else {
                        msg = error.toString();
                    }
                }
                this.warehouseDialog.errorMsg = msg
            }).finally(() => {
                this.loading = false
            })
        },
        setWarehouseFromDialog(warehouse) {
            this.warehouseDialog.show = false
            this.warehouseDialog.warehouse = warehouse
            this.warehouseDialog.errorMsg = []
            this.mengeInput.focus = true
            this.warehouseDialog.storagePlace = {}
            this.checkWarehouse()
        },
        checkWarehouse() {
            let stockData = this.getArticleStockDataByIndex
            this.mengeInput.disabled = false
            this.warehouseDialog.alert.show = false
            // nur Lager prüfen
            if (parseInt(stockData.Lagertyp) === 0) {
                if (this.warehouseDialog.warehouse.Number.toString() === stockData.Lager.toString()) {
                    this.warehouseDialog.alert.msg = 'Ziel-Lager ist identisch!'
                    this.warehouseDialog.alert.show = true
                    this.mengeInput.disabled = true
                }
            }
        },
        getStoragePlaceDimensions(stockData) {
            // bereits storagePlace Objekt?
            let storagePlace = stockData
            if (stockData.LagerplatzDimension1) {
                storagePlace = {
                    'Dimension1': stockData.LagerplatzDimension1,
                    'Dimension2': stockData.LagerplatzDimension2,
                    'Dimension3': stockData.LagerplatzDimension3
                }
            }
            return LagerService.getStoragePlaceDimensions(storagePlace)
        },
        setStoragePlace(storagePlace) {
            this.warehouseDialog.storagePlace = storagePlace
            this.$refs.MengeInput.$refs.menge.focus()
            this.checkStoragePlace()
        },
        checkStoragePlace() {
            let stockData = this.getArticleStockDataByIndex
            this.mengeInput.disabled = false
            this.warehouseDialog.alert.show = false
            // nur Lager prüfen
            if (parseInt(stockData.Lagertyp) > 0) {
                if (this.getStoragePlaceDimensions(stockData) === this.getStoragePlaceDimensions(this.warehouseDialog.storagePlace)) {
                    this.warehouseDialog.alert.msg = 'Ziel-Lagerplatz ist identisch!'
                    this.warehouseDialog.alert.show = true
                    this.mengeInput.disabled = true
                }
            }
        },
        hatLagerplaetze(warehouse) {
            return LagerService.hatLagerplaetze(warehouse)
        }
    },
    mounted() {
        this.focusArtikelField()
    }
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}

.article-data {
    margin: 10px 0;
}

.article-data .menge {
    margin: 10px 0;
    font-weight: bold;
}

.bestand .item {
    padding: 2px 10px 2px 30px;
    font-size: 1rem;
}

.bestand h3 {
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
}

.soll-bestand {
    font-weight: bold;
    font-size: 1rem;
}
</style>
