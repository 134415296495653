import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import Vue from "vue";
import {AUTH_ERROR, AUTH_LOGOUT, /*AUTH_LOGIN, AUTH_SUCCESS*/} from "../actions/auth";
import {LOADER_HIDE, LOADER_SHOW} from "../actions/loader";
import UserService from "../../services/UserService";

const state = { status: "", profile: {} };

const getters = {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.name
};

const actions = {
    [USER_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST);
            commit(LOADER_SHOW);

            UserService.getUser()
                .then(response => {
                    commit(USER_SUCCESS, response);
                    resolve(response);
                })
                .catch(error => {
                    //window.console.log(error.response);
                    commit(AUTH_ERROR, error);
                    reject(error);
                })
                .finally(() => {
                    commit(LOADER_HIDE);
                });
        });
    }
};

const mutations = {
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = "success";
        Vue.set(state, "profile", resp.data);
    },
    [USER_ERROR]: state => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
        state.profile = {};
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
