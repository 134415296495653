<template>
    <v-bottom-sheet :value="showMessage">
        <v-sheet class="text-center api-error-message">
            <v-alert class="mb-0" type="error" dismissible prominent>
                <v-row align="center">
                    <v-col class="grow">
                        Beim Zugriff auf die Url<br><code>{{ getUrl }}</code><br>ist ein Fehler aufgetreten: <br>
                        <code class="pa-2">{{ getMessage }}</code>
                        <div v-if="hasDetails" class="text-center mt-2">
                            <v-dialog
                                    v-model="dialogDetails"
                                    width="500"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            color="red lighten-2"
                                            dark
                                            v-on="on"
                                    >
                                        Details anzeigen
                                    </v-btn>
                                </template>

                                <v-card class="details">
                                    <v-card-title
                                            class="headline"
                                            primary-title
                                    >
                                        Fehler Details
                                    </v-card-title>

                                    <v-card-text>
                                        {{ getDetails }}
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="primary"
                                                text
                                                @click="dialogDetails = false"
                                        >
                                            Schließen
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                    </v-col>
                    <!--
                    <v-col class="shrink">
                        <v-btn @click="hideMessage">schließen</v-btn>
                    </v-col>
                    -->
                </v-row>
            </v-alert>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
    import {ERROR_HIDE} from "../../store/actions/ApiClientError";

    export default {
        name: "ApiClientErrorMessage",
        data() {
            return {
                dialogDetails: false,
            }
        },
        computed: {
            showMessage: function () {
                return this.$store.getters.getShowError
            },
            getMessage: function () {
                return this.$store.getters.getMessage
            },
            getDetails: function () {
                return this.$store.getters.getDetails
            },
            getUrl: function () {
                return this.$store.getters.getUrl
            },
            hasDetails: function () {
                return (this.$store.getters.getDetails !== '')
            }
        },
        methods: {
            hideMessage: function () {
                this.$store.commit(ERROR_HIDE)
            },
        }
    }
</script>

<style scoped>
    .api-error-message code {
        word-break: break-all;
    }
    .details .v-card__text {
        white-space: pre-wrap;
    }
</style>
