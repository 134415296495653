import TypeChecks from "@/utils/typecheck";
import moment from "moment";

const storageItem = 'app-config'
const configFile = 'config.json'
const configFileDefault = 'config.default.json'
const currentFile = process.env.NODE_ENV === 'production' ? configFile : configFileDefault
let configLoaded = false
let configChanged = false
let lastLoaded
// interval alle 10 Minuten
const interval = 1000 * 60 * 10

/**
 * Initialisierung
 */
const init = () => {
    let activeConfig = localStorage.getItem(storageItem)
    if (activeConfig !== null) {
        configLoaded = true
        startInterval()
    } else {
        fetchConfig(currentFile)
            .then(config => {
            })
            .catch(error => {
                document.dispatchEvent(
                    new CustomEvent('configFetchError')
                );
            })
            .finally(() => {
                startInterval()
            })
    }
}

/**
 * Loading config with interval
 */
const startInterval = () => {
    // Loading config with interval
    setInterval(() => {
        fetchConfig(currentFile)
            .then(config => {
            })
            .catch(error => {
                console.log(error)
            })
    }, interval);
}

/**
 * Fetch config-file from server
 *
 * @param url
 * @returns {Promise<unknown>}
 */
const fetchConfig = (url) => {
    if (TypeChecks.isUndefined(url)) {
        url = currentFile
    }
    let headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    let init = {
        method: 'GET',
        headers: headers,
    };

    return new Promise((resolve, reject) => {
        fetch(url, init)
            .then(response => response.text())
            .then(data => {
                if (TypeChecks.isJsonString(data)) {
                    configLoaded = true
                    lastLoaded = moment()
                    saveConfig(data)
                    resolve(data)
                } else {
                    reject('NO JSON')
                }
            })
            .catch(error => {
                reject(error)
            }).finally(() => {
        })
    })
}

/**
 * Save the config in localStorage
 *
 * @param config
 */
const saveConfig = (config) => {
    configChanged = false
    if (isConfigChanged(config)) {
        configChanged = true
        localStorage.setItem(storageItem, config)
        document.dispatchEvent(
            new CustomEvent('configUpdated')
        );
    }
}

/**
 * Check if config is empty
 *
 * @param config
 * @returns {*}
 */
const isConfigEmpty = (config) => {
    return TypeChecks.isEmptyObject(config)
}

/**
 * Check if config data has changed
 *
 * @param newConfig
 * @returns {boolean}
 */
const isConfigChanged = (newConfig) => {
    let activeConfig = getConfig()
    if (isConfigEmpty(activeConfig)) {
        return true
    }
    return (JSON.stringify(JSON.parse(newConfig)) !== JSON.stringify(activeConfig))
}

/**
 * Get complete config from localStorage
 *
 * @returns {{}|any}
 */
const getConfig = () => {
    let storage = localStorage.getItem(storageItem)
    if (storage !== null && TypeChecks.isJsonString(storage)) {
        return JSON.parse(storage)
    } else {
        return {}
    }
}

/**
 * Get url to SelectLine API
 * @returns {string}
 */
const getSelectLineApiUrl = () => {
    let config = getConfig()
    if (TypeChecks.isUndefined(config.SelectLineApi)) {
        return ''
    } else {
        return config.SelectLineApi.url
    }
}

/**
 * Trust SSL certificate of SelectLine API
 * @returns {boolean}
 */
const getSelectLineApiUrlTrustSSL = () => {
    let config = getConfig()
    if (TypeChecks.isUndefined(config.SelectLineApi)) {
        return true
    } else {
        return config.SelectLineApi.trustSSL
    }
}

/**
 * Is Config loaded from server?
 * @returns {boolean}
 */
const isConfigLoaded = () => {
    return configLoaded
}

/**
 * Has config changed?
 * @returns {boolean}
 */
const hasConfigChanged = () => {
    if (configChanged) {
        document.dispatchEvent(
            new CustomEvent('configUpdated')
        );
    }
    return configChanged
}

/**
 * Get last loaded datetime
 * @returns {string}
 */
const getLastLoaded = () => {
    return lastLoaded
}

/**
 * ConfigService
 */
export default {
    storageItem,
    configFile,
    configFileDefault,
    init,
    getConfig,
    fetchConfig,
    isConfigLoaded,
    hasConfigChanged,
    getLastLoaded,
    getSelectLineApiUrl,
    getSelectLineApiUrlTrustSSL,
}
