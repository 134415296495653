<template>
    <DataCard title="LagerApp-Log" @goBack="goBack">
        <template slot="text">
            <v-row>
                <v-col cols="5">Anzahl Scans:<br><strong>{{ anzahlScans }}</strong></v-col>
                <v-col cols="7">
                    <DatumInput
                        v-bind:show-arrows="false"
                        v-bind:nudge-right="-50"
                        v-bind:events="getEvents"
                        @updateDate="updateDate" />
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="logs"
                item-key="Nummer"
                :items-per-page="8"
                :search="search"
                :calculate-widths="true"
                :loading="loading"
                sort-by="_GESCANNTAM"
                loading-text="Daten werden geladen... bitte warten"
                mobile-breakpoint="0"
                class="lageapplog-table elevation-1"
                dense
                :footer-props="{
                        itemsPerPageOptions:[8, 10, 25, 50, 100]
                    }"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Suche"
                        single-line
                        hide-details
                        class="mx-4 pb-1"
                    ></v-text-field>
                </template>
                <template v-slot:item="{item}">
                    <tr>
                        <td v-for="header in headers">
                            <span v-if="header.type === 'datetime'">
                                {{ item[header.value] | formatDateTime }}
                            </span>
                            <span v-else>
                                {{ item[header.value] }}
                            </span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </DataCard>
</template>

<script>
import DataCard from "@/components/cards/DataCard";
import LagerService from "@/services/LagerService";
import moment from "moment";
import MacroService from "@/services/MacroService";
import DatumInput from "@/components/forms/DatumInput";

export default {
    name: "LagerAppLog",
    components: {DatumInput, DataCard},
    data: () => {
        return {
            loading: false,
            logs: [],
            logsByDate: [],
            date: moment(),
            search: '',
        }
    },
    computed: {
        headers() {
            return [
                {text: 'Gescannt am', value: '_GESCANNTAM', type: 'datetime'},
                {text: 'Artikelnummer', value: '_ARTIKELNUMMER'},
            ]
        },
        anzahlScans: function () {
            return this.logs.length
        },
        getEvents: function () {
            return this.logsByDate.map((element) => {
                if (element.Datum !== null) {
                    return moment(element.Datum).format('YYYY-MM-DD')
                }
            })
        }
    },
    watch: {
        date: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getLagerAppLogs()
            }
        }
    },
    methods: {
        goBack() {
            this.$router.push({name: 'Artikel'})
        },
        getLagerAppLogs: function () {
            this.loading = true
            LagerService.getLagerAppLogByDay(this.date).then(response => {
                this.logs = MacroService.combineSelectData(response.data)
                this.loading = false
            })
        },
        getLagerAppLogsAggregated: function () {
            LagerService.getLagerAppLogByUserAndDateAggregated(this.date).then(response => {
                this.logsByDate = MacroService.combineSelectData(response.data)
            })
        },
        updateDate: function (date) {
            this.date = date
        }
    },
    created() {
        this.getLagerAppLogs()
        this.getLagerAppLogsAggregated()
    }
}
</script>

<style scoped>
.v-data-table {
    margin-top: -30px;
}
.v-data-table .v-data-footer {

}
.v-data-table .v-data-footer .v-btn--icon.v-size--default {
    width: 20px;
}
</style>
