<template>
    <v-row no-gutters>
        <v-col v-if="showArrows" align-self="center" cols="3">
            <v-btn class="float-left" depressed @click="changeDateDay('-')">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </v-col>
        <v-col align-self="center" class="pr-1" :cols="showArrows ? '6' : '12'">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-left="nudgeLeft"
                :nudge-right="nudgeRight"
                min-width="auto"
                offset-y
                transition="scale-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFormatted"
                        v-bind="attrs"
                        v-on="on"
                        :label="label"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    :events="events"
                    :first-day-of-week="1"
                    event-color="green lighten-1"
                    locale="de-de"
                    scrollable
                    show-adjacent-months
                    @input="menu = false"
                ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col v-if="showArrows" align-self="center" cols="3">
            <v-btn class="float-right" depressed @click="changeDateDay('+')">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DatumInput",
    props: {
        events: {
            type: Array,
            default: () => [],
        },
        boolSpeichereDatum: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: 'Datum',
        },
        showArrows: {
            type: Boolean,
            default: true
        },
        nudgeLeft: {
            type: Number,
            default: 80
        },
        nudgeRight: {
            type: Number,
            default: 0
        }
    },
    data: vm => ({
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
            menu: false,
    }),
    watch: {
        date: function () {
            // Anpassung von formatiertem Datum
            this.dateFormatted = this.formatDate(this.date)
            // Bei Änderung von Datum, weitergeben an Eltern
            this.$emit('updateDate', this.date);
            if (this.boolSpeichereDatum) window.localStorage.setItem('belegDatumPagination', this.date);
        }
    },
    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        changeDateDay(str) {
            // Bei Knopfdruck Datum erhöhen/verringern
            switch (str) {
                case '+':
                    this.date = new Date(new Date(this.date).valueOf() + 864E5).toISOString().substr(0, 10)
                    break;
                case '-':
                    this.date = new Date(new Date(this.date).valueOf() - 864E5).toISOString().substr(0, 10)
                    break;
                default:

                    break;
            }
        },
    },
    created: function () {
        // Wenn Datum gespeichert werden soll, dann Prüfe LocalStorage
        if (this.boolSpeichereDatum && window.localStorage.getItem('belegDatumPagination') !== null) {
            this.date = window.localStorage.getItem('belegDatumPagination');
            this.$emit('updateDate', this.date);
        }
    },
}
</script>

<style scoped>
.v-picker >>> .v-date-picker-table .accent {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    color: #000 !important;
}

.v-picker >>> .v-date-picker-table .accent--text {
    color: #000 !important;
    caret-color: #000 !important;
}

/*.v-picker >>> .v-date-picker-table .accent {*/
/*    background-color: #005caf !important;*/
/*    border-color: #005caf !important;*/
/*}*/
/*.v-picker >>> .v-date-picker-table .accent--text {*/
/*    color: #005caf !important;*/
/*    caret-color: #005caf !important;*/
/*}*/
</style>
