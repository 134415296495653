import TypeChecks from "../utils/typecheck";

const functions = {
    /**
     * Formatiere die Rückgabe eines API-Makro Aufrufs
     */
    combineSelectData: function (data, sortField) {
        let returnData = [];
        for (let r = 0; r < data.Rows.length; r++) {
            let row = {};
            for (let n = 0; n < data.ColumnNames.length; n++) {
                // Check of Json String and make sub-Object
                if (TypeChecks.isJsonString(data.Rows[r].ColumnValues[n])) {
                    row[data.ColumnNames[n]] = JSON.parse(data.Rows[r].ColumnValues[n])
                } else {
                    row[data.ColumnNames[n]] = data.Rows[r].ColumnValues[n]
                }
            }
            returnData.push(row);
        }

        if (typeof sortField !== "undefined") {
            returnData = returnData.sort(function (a, b) {
                let valueA = a[sortField];
                let valueB = b[sortField];
                if (TypeChecks.isString(a[sortField]) && TypeChecks.isString(b[sortField])) {
                    // Use toUpperCase() to ignore character casing
                    valueA = a[sortField].toUpperCase();
                    valueB = b[sortField].toUpperCase();
                }

                let comparison = 0;
                if (valueA > valueB) {
                    comparison = 1;
                } else if (valueA < valueB) {
                    comparison = -1;
                }
                return comparison;
            });
        }

        return returnData
    }
};

export default functions
