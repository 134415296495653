import { LOADER_SHOW, LOADER_HIDE } from "../actions/loader";

const state = { show: false };

const getters = {
    getShow: state => state.show,
};

const mutations = {
    [LOADER_SHOW]: state => {
        state.show = true;
    },
    [LOADER_HIDE]: (state) => {
        state.show = false;
    },
};

export default {
    state,
    getters,
    mutations
};
