<template>
    <div>
        <v-card class="mx-auto">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        {{ getTitle }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Hauptmenü
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list nav>
                <v-list-item
                        v-for="(menu) in this.activeLinks()"
                        v-bind:key="menu.id"
                        :to="menu.page"
                >
                    <v-list-item-action>
                        <v-icon>{{menu.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{menu.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list two-line>
                <v-list-item-group>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Mandant</v-list-item-title>
                            <v-list-item-subtitle v-text="this.getMandant"></v-list-item-subtitle>
                            <v-list-item-subtitle v-text="this.getMandantVersion"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Angemeldet am</v-list-item-title>
                            <v-list-item-subtitle v-text="this.getLoginTime"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script>
    import Menu from "../menu";
    import moment from "moment";

    export default {
        name: "Home",
        data: () => ({
            title: 'Home',
            info: Object
        }),
        computed: {
            getMandant: function () {
                return this.$store.getters.getProfile.TenantDatabaseName
            },
            getMandantVersion: function () {
                return this.$store.getters.getProfile.TenantDatabaseVersion
            },
            getUsername: function () {
                return this.$store.getters.getProfile.DisplayName
            },
            getTitle: function () {
                return ('Willkommen ' + this.$store.getters.getProfile.DisplayName)
            },
            getLoginTime: function () {
                moment.locale('de');
                return moment(this.$store.getters.getProfile.LoginTime).format("dddd, DD MMMM YYYY, HH:mm:ss");
            }
        },
        methods: {
            activeLinks: function () {
                return Menu.filter(link => {
                    return link.show();
                });
            },
        },
        mounted() {
        },
        created() {

        },
    }
</script>

<style scoped>
    .hidden {
        display: none !important;
    }

    #installContainer {

    }

    #installContainer button {

    }
</style>
