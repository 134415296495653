<template>
    <DataCard title="Konfiguration" @goBack="goBack">
        <template v-slot:text>
            <v-list class="info-list">
                <v-list-item
                    two-line
                    class="pa-0"
                >
                    <v-list-item-content>
                        <v-list-item-title>SelectLine API Url</v-list-item-title>
                        <v-list-item-subtitle>{{ SLAPIUrl }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    two-line
                    class="pa-0"
                >
                    <v-list-item-content>
                        <v-list-item-title>Vertraue dem SSL Zertifikat</v-list-item-title>
                        <v-list-item-subtitle>{{ trustSSL }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div class="pb-1 text-center">akt. {{ lastLoadedAt | formatDateTime }}</div>
            <v-btn block color="secondary" @click="fetchConfig" :loading="loading">aktualisieren
                <v-icon class="pl-2">mdi-cog-refresh</v-icon>
            </v-btn>
        </template>
    </DataCard>
</template>

<script>
import ConfigService from "@/services/ConfigService";
import DataCard from "@/components/cards/DataCard";

export default {
    name: "Konfiguration",
    components: {
        DataCard
    },
    data: () => {
        return {
            config: {},
            lastLoadedAt: null,
            loading: false
        }
    },
    computed: {
        SLAPIUrl() {
            return ConfigService.getSelectLineApiUrl()
        },
        trustSSL() {
            return ConfigService.getSelectLineApiUrlTrustSSL()
        }
    },
    methods: {
        goBack() {
            this.$router.push({name: 'Home'})
        },
        fetchConfig() {
            this.loading = true
            ConfigService.fetchConfig()
                .then(config => {
                    this.config = config
                    this.lastLoadedAt = ConfigService.getLastLoaded()
                })
                .finally(() => this.loading = false)
        }
    },
    created() {
        this.fetchConfig()
    }
}
</script>

<style scoped>

</style>
