<template>
    <v-dialog v-model="showDialog" transition="dialog-bottom-transition" max-width="600">
        <DataCard title="Artikel auswählen" @closeDialog="closeDialog" :loading="loading">
            <template v-slot:text>
                <v-text-field
                    v-if="showDialog"
                    autofocus
                    ref="searchField"
                    class="ml-auto"
                    :loading="searchField.loading"
                    v-model="searchField.value"
                    placeholder="Artikel suchen"
                    v-debounce:400ms="searchArticles"
                    append-icon="mdi-close"
                    @click:append="clearSearch"
                    :messages="searchField.messages"
                    :success="searchField.success"
                    :success-messages="searchField.successMsg"
                    :error-messages="searchField.errorMsg"
                    :error="searchField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="searchField.readOnly"
                    :disabled="searchField.disabled"
                    outlined
                />
                <v-divider/>
                <v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>
                <v-list>
                    <v-list-item v-for="(article) in articles.slice(0, 50)" :key="article.Number"
                                 @click="setArtikelFromDialog(article)">
                        <v-list-item-content>
                            <v-list-item-title>{{ article.Name }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ article.AdditionalDescription }}<br>
                                {{ article.Number }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </DataCard>
    </v-dialog>
</template>

<script>
import ArtikelService from "@/services/ArtikelService";
import DataCard from "@/components/cards/DataCard";

export default {
    name: "ArtikelAuswahlDialog",
    components: {
        DataCard
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    data: () => {
        return {
            showDialog: false,
            loading: false,
            searchField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                messages: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            searchTime: null,
            articles: [],
            alert: {
                show: false,
                msg: ''
            }
        }
    },
    watch: {
        show: function (newVal) {
            this.showDialog = newVal
        },
    },
    methods: {
        clearSearch() {
            this.searchField.value = ''
            this.searchField.success = false
            this.searchField.successMsg = []
            this.searchField.error = false;
            this.searchField.errorMsg = []
            this.articles = []
        },
        openDialog() {
            this.showDialog = true
        },
        closeDialog() {
            this.showDialog = false
            this.$emit('closeArtikelDialog');
        },
        setArtikelFromDialog(article) {
            this.clearSearch()
            this.closeDialog()
            this.$emit('setArtikelFromDialog', article);
        },
        searchArticles() {
            if (this.searchField.value.length > 0) {
                this.alert.show = false
                this.alert.msg = ''
                this.loading = true
                let searchValues = this.searchField.value.split(" ")
                let filter = `IsWarehouseArtikel EQ 1`
                searchValues.forEach(function (val) {
                    let filterFields = []
                    filterFields.push(`Number CT '${val}'`)
                    filterFields.push(`EuropeanArticleNumber CT '${val}'`)
                    filterFields.push(`Name CT '${val}'`)
                    filterFields.push(`AdditionalDescription CT '${val}'`)
                    filter += ` AND (${filterFields.join(' OR ')})`
                })
                ArtikelService.getArtikel(filter).then(response => {
                    this.articles = response.data
                    if (this.articles.length === 0) {
                        this.alert.show = true
                        this.alert.msg = 'Keine Artikel gefunden!'
                    }
                }).catch(error => {
                    console.log(error)
                    if (typeof error.response !== "undefined") {
                        this.alert = error.response;
                    } else {
                        this.alert = error.toString();
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}
</style>
