<template>
    <v-snackbar
            v-model="showInstallBanner"
            top
            :timeout=10000
    >
        <v-btn @click="installApp" color="primary">App installieren?</v-btn>
        <v-btn
            color="white"
            icon
            @click="hideBanner"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-snackbar>

</template>

<script>
    let installEvent;
    export default {
        name: 'InstallPrompt',
        data() {
            return {
                showInstallBanner: false,
                hideInstallBanner: false,
            };
        },
        methods: {
            installApp: function () {
                //console.log('👍', 'butInstall-clicked');
                if (!installEvent) {
                    // The deferred prompt isn't available.
                    return;
                }
                // Show the install prompt.
                installEvent.prompt();
                // Log the result
                installEvent.userChoice.then((result) => {
                    // eslint-disable-next-line no-console
                    console.log('👍', 'userChoice', result);
                    // Reset the deferred prompt variable, since
                    // prompt() can only be called once.
                    installEvent = null;

                    this.showInstallBanner = false;
                });
            },
            hideBanner: function () {
                this.hideInstallBanner = true;
                this.showInstallBanner = false;
            }
        },
        watch: {
            hideInstallBanner(newValue) {
                localStorage.hideInstallBanner = newValue;
            }
        },
        created() {
        },
        mounted() {
            if (typeof localStorage.hideInstallBanner !== "undefined") {
                this.hideInstallBanner = localStorage.hideInstallBanner;
            }

            if (!this.hideInstallBanner) {
                window.addEventListener('beforeinstallprompt', (event) => {
                    //console.log('👍', 'beforeinstallprompt', event);
                    // Stash the event so it can be triggered later.
                    installEvent = event;

                    this.showInstallBanner = true;
                });

                window.addEventListener('appinstalled', (event) => {
                    // eslint-disable-next-line no-console
                    console.log('👍', 'appinstalled', event);
                });
            }
        }
    };
</script>

<style scoped>

</style>
