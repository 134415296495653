import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import auth from "./modules/auth";
import info from "./modules/info";
import loader from "./modules/loader";
import progressbar from "./modules/progressbar";
import ApiClientError from "./modules/ApiClientError";
import notifications from "./modules/notifications";
import connection from "./modules/connection";
import timer from "./modules/timer";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        user,
        auth,
        info,
        loader,
        progressbar,
        ApiClientError,
        notifications,
        timer,
        connection
    },
    strict: debug
});
