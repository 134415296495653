<template>
    <v-row>
        <v-col class="col-auto mr-auto">
            <v-chip>
                <v-avatar left :class="classLeftChip">{{ valueLeftChip }}</v-avatar>
                {{ textLeftChip }}
            </v-chip>
        </v-col>
        <v-col class="col-auto">
            <v-chip :disabled="!isOnline">
                <v-avatar left :class="classRightChip">{{ valueRightChip }}</v-avatar>
                {{ textRightChip }}
            </v-chip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "DataTransferChips",
    props: {
        valueLeftChip: {
            type: Number,
            default: null
        },
        valueRightChip: {
            type: Number,
            default: null
        },
        textLeftChip: {
            type: String,
            default: ''
        },
        textRightChip: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            classLeftChip: {
                type: String,
                default: ''
            },
            classRightChip: {
                type: String,
                default: ''
            },
        }
    },
    computed: {
        isOnline: function () {
            return this.$store.getters['connection/isOnline']
        },
    },
    watch: {
        valueLeftChip: function () {
            this.updateChipClasses()
        },
        valueRightChip: function () {
            this.updateChipClasses()
        }
    },
    methods: {
        updateChipClasses() {
            if (this.valueLeftChip === this.valueRightChip) {
                this.classLeftChip = 'green'
                this.classRightChip = 'green'
            } else if (this.valueLeftChip > this.valueRightChip) {
                this.classRightChip = 'yellow'
                if (!this.isOnline) {
                    this.classLeftChip = 'yellow'
                }
            } else {
                this.classLeftChip = 'red'
            }
        },
    },
    mounted() {
        this.updateChipClasses()
    }
}
</script>

<style scoped>

</style>
