<template>
    <div>
        <v-text-field
            ref="menge"
            v-model="value"
            :placeholder="placeholder"
            prepend-icon="mdi-minus"
            append-outer-icon="mdi-plus"
            @click:prepend="decreaseMenge"
            @click:append-outer="increaseMenge"
            @keyup="mengeKeyup"
            @change="setInputValue"
            :error-messages="errorMsg"
            :error="error"
            :disabled="disabled"
            :class="styleBold ? 'font-weight-bold' : ''"
            :suffix="suffix"
            outlined
            type="number"
        />
        <v-btn block :disabled="disabled" color="primary" @click="confirmQuantity" :loading="loading">{{ textButton }}</v-btn>
    </div>
</template>

<script>
//import TypeChecks from "@/utils/typecheck";

export default {
    name: "MengeInput",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        textButton: {
            type: String,
            default: 'Menge bestätigen'
        },
        placeholder: {
            type: String,
            default: 'Menge'
        },
        error: {
            type: Boolean,
            default: false
        },
        errorMsg: {
            type: String,
            default: ''
        },
        focus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        styleBold: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            value: null,
            inputValue: null
        }
    },
    watch: {
        focus: function (newVal) {
            if (newVal) {
                this.focusField()
            }
        },
        /*
        inputValue: function (newValue) {
            if (newValue !== null) {
                newValue = parseFloat(newValue.replace('.', '').replace(',', '.').replace(' ', ''))
                if (TypeChecks.isNumber(newValue)) {
                    this.setValue(newValue)
                }
            }
        }
        */
    },
    methods: {
        increaseMenge() {
            this.value++
            this.setInputValue()
        },
        decreaseMenge() {
            if (this.value > 0) {
                this.value--
                this.setInputValue()
            }
        },
        mengeKeyup(e) {
            // on ENTER
            /*
            if (e.keyCode === 13) {
                if (this.mengeField.value > 0) {
                    this.confirmArticleQuantity()
                } else {
                    this.mengeField.error = true
                }
            }*/
        },
        confirmQuantity() {
            this.$emit('confirmQuantity', parseFloat(this.value));
        },
        focusField() {
            this.$refs.menge.focus()
        },
        setInputValue() {
            this.inputValue = this.$options.filters.formatNumber(this.value)
        },
        setValue(quantity) {
            this.value = quantity;
        },
        resetValue() {
            this.value = null
            this.inputValue = null
        }
    },

}
</script>

<style scoped>

</style>
