/**
 * Connection status
 */
const state = {
    online: navigator.onLine
};

const getters = {
    isOnline: state => state.online,
    isOffline: state => !state.online,
};

const actions = {
    setOnlineState: ({commit}, onlineState) => {
        commit('onlineState', onlineState);
    },
};

const mutations = {
    onlineState:(state, onlineState) => {
        state.online = onlineState
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
