<template>
    <div>
        <v-navigation-drawer
                :clipped="drawer.clipped"
                :fixed="drawer.fixed"
                :permanent="drawer.permanent"
                :mini-variant="drawer.mini"
                v-model="drawer.open"
                app
        >
            <v-list>
                <!--
                <v-list-item
                        v-if="!drawer.permanent"
                        @click="makeDrawerPermanent">
                    <v-list-item-action>
                        <v-icon>chevron_right</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Static Drawer</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="toggleMiniDrawer">
                    <v-list-item-action>
                        <v-icon>aspect_ratio</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mini Drawer</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                -->

                <v-list-item
                        v-for="(link, key) in activeLinks"
                        v-bind:key="key"
                        :to="link.page"
                >
                    <v-list-item-action>
                        <v-icon>{{link.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{link.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block color="white" to="Konfiguration">Konfiguration <v-icon class="pl-2">mdi-cog</v-icon></v-btn>
                </div>
                <div class="pa-2">
                    <v-btn block color="primary" @click="logout">Logout <v-icon class="pl-2">mdi-logout</v-icon></v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar app
                   dark
                   dense
                   color="indigo">
            <v-app-bar-nav-icon @click="toggleDrawer"/>

            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer/>

            <!--
            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            -->

            <StatusBullets />

            <v-btn icon v-if="$store.getters.isAuthenticated" @click="logout"><v-icon class="text--white">mdi-logout</v-icon></v-btn>

            <!--
            <v-menu left bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item
                            v-for="n in 5"
                            :key="n"
                            @click="() => {}"
                    >
                        <v-list-item-title>Option {{ n }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            -->
        </v-app-bar>
    </div>
</template>

<script>
    import {AUTH_LOGOUT } from "../../store/actions/auth";
    import Menu from "../../menu";
    import StatusBullets from "./StatusBullets";
    import ConfigService from "@/services/ConfigService";

    export default {
        name: "Navigation",
        components: {
            StatusBullets
        },
        data: () => ({
            drawer: {
                // sets the open status of the drawer
                open: false,
                // sets if the drawer is shown above (false) or below (true) the toolbar
                clipped: false,
                // sets if the drawer is CSS positioned as 'fixed'
                fixed: true,
                // sets if the drawer remains visible all the time (true) or not (false)
                permanent: false,
                // sets the drawer to the mini variant, showing only icons, of itself (true)
                // or showing the full drawer (false)
                mini: false
            },
            toolbar: {
                //
                fixed: true,
                // sets if the toolbar contents is leaving space for drawer (false) or not (true)
                clippedLeft: false
            },
            footer: {
                // sets the CSS position of the footer
                fixed: true,
                // sets if the footer is full width (true) or gives space to the drawer (false)
                clippedLeft: true
            },
            links: Menu,
        }),

        computed: {
            activeLinks: function () {
                return this.links.filter(link => {
                    return link.show();
                });
            },
        },

        props: {
            title: String,
            source: String,
        },

        methods: {
            // changes the drawer to permanent
            makeDrawerPermanent() {
                this.drawer.permanent = true
                // set the clipped state of the drawer and toolbar
                this.drawer.clipped = false
                this.toolbar.clippedLeft = false
            },
            // toggles the drawer variant (mini/full)
            toggleMiniDrawer() {
                this.drawer.mini = !this.drawer.mini
            },
            // toggles the drawer type (permanent vs temporary) or shows/hides the drawer
            toggleDrawer() {
                if (this.drawer.permanent) {
                    this.drawer.permanent = !this.drawer.permanent
                    // set the clipped state of the drawer and toolbar
                    this.drawer.clipped = true
                    this.toolbar.clippedLeft = true
                } else {
                    // normal drawer
                    this.drawer.open = !this.drawer.open
                }
            },
            getTitle() {
                return this.props.title;
            },
            logout() {
                this.$store.dispatch(AUTH_LOGOUT)
                    .then(() => {
                        this.$router.push('/login')
                    })
            },
            reload() {
                location.reload();
            },
            fetchConfig() {
                ConfigService.fetchConfig()
            }
        }
    }
</script>
