<template>
    <v-dialog v-model="showDialog" transition="dialog-bottom-transition" max-width="600">autofocus
        <DataCard title="Lager auswählen" @closeDialog="closeWarehouseDialog" :loading="loading">
            <template v-slot:text>
                <v-text-field
                    ref="warehouse"
                    class="ml-auto"
                    :loading="searchField.loading"
                    v-model="searchField.value"
                    placeholder="Lager suchen"
                    v-debounce:400ms="searchWarehouses"
                    append-icon="mdi-close"
                    @click:append="clearSearch"
                    :messages="searchField.messages"
                    :success="searchField.success"
                    :success-messages="searchField.successMsg"
                    :error-messages="searchField.errorMsg"
                    :error="searchField.error"
                    :hide-details=false
                    :error-count=1
                    :readonly="searchField.readOnly"
                    :disabled="searchField.disabled"
                    outlined
                    autofocus
                />
                <v-divider/>
                <!--<v-alert type="error" v-model="alert.show">{{ alert.msg }}</v-alert>-->
                <v-list>
                    <v-list-item v-for="(warehouse) in warehouses.slice(0, 50)" :key="warehouse.Number"
                                 @click="setWarehouseFromDialog(warehouse)">
                        <v-list-item-icon>{{ warehouse.Number }}</v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ warehouse.Name }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ warehouse.AdditionalDescription }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </DataCard>
    </v-dialog>
</template>

<script>
import DataCard from "@/components/cards/DataCard";
import LagerService from "@/services/LagerService";

export default {
    name: "LagerAuswahlDialog",
    components: {
        DataCard
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    data: () => {
        return {
            showDialog: false,
            loading: false,
            searchField: {
                value: '',
                success: false,
                error: false,
                successMsg: [],
                errorMsg: [],
                messages: [],
                loading: false,
                readOnly: false,
                disabled: false,
            },
            searchTime: null,
            warehouses: []
        }
    },
    watch: {
        show: function (newVal) {
            this.showDialog = newVal
        },
    },
    methods: {
        focusField() {
            this.$refs.warehouse.focus()
        },
        searchWarehouses() {
            let searchValue = this.searchField.value
            let filter = `(Number CT '${searchValue}' OR Name CT '${searchValue}')`;
            this.getWarehouses(filter)
        },
        clearSearch() {
            this.searchField.value = ''
            this.searchField.success = false
            this.searchField.successMsg = []
            this.searchField.error = false;
            this.searchField.errorMsg = []
            this.getWarehouses()
        },
        setWarehouseFromDialog(warehouse) {
            this.showDialog = false
            this.$emit('setWarehouseFromDialog', warehouse);
        },
        closeWarehouseDialog() {
            this.showDialog = false
            this.$emit('closeWarehouseDialog');
        },
        getWarehouses(filter) {
            this.loading = true
            LagerService.getWarehouses(filter).then(response => {
                this.warehouses = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created() {
        this.getWarehouses()
    },
    mounted() {
    }
}
</script>

<style scoped>
.v-list-item {
    border: 1px solid #cccccc;
    border-radius: 5px;
}

.v-list-item + .v-list-item {
    margin-top: 5px;
}

.v-list-item__icon {
    font-weight: bold;
    font-size: 1rem;
}
</style>
