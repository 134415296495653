<template>
    <v-card :loading="loading">
        <v-card-title v-if="title">
            {{ title }}
            <v-spacer/>
            <v-btn icon @click="goBack" v-if="hasGoBackListener">
                <v-icon>mdi-reply</v-icon>
            </v-btn>
            <v-btn icon @click="closeDialog" v-if="hasCloseDialogListener">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="subtitle" v-text="subtitle"/>
        <v-card-text>
            <slot name="text"/>
        </v-card-text>
        <v-card-actions>

        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "DataCard",
    props: {
        title: String,
        subtitle: String,
        data: Array,
        index: String,
        loading: Boolean
    },
    computed:{
        hasGoBackListener(){
            return this.$listeners && this.$listeners.goBack
        },
        hasCloseDialogListener(){
            return this.$listeners && this.$listeners.closeDialog
        }
    },
    methods: {
        /*
        goBack() {
            this.$router.go(-1)
        },
        */
        goBack() {
            this.$emit('goBack');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>

<style scoped>

</style>
