/**
 * Type Checks
 *
 * @type {{isString: (function(*=): boolean)}}
 */

const TypeChecks = {
    isString: (value) => {
        return typeof value === 'string' || value instanceof String;
    },
    // Returns if a value is really a number
    isNumber: (value) => {
        return typeof value === 'number' && isFinite(value);
    },
    isArray: (value) => {
        return value && typeof value === 'object' && value.constructor === Array;
    },
    isFunction: (value) => {
        return typeof value === 'function';
    },
    isObject: (value) => {
        return value && typeof value === 'object' && value.constructor === Object;
    },
    isNull: (value) => {
        return value === null;
    },
    isUndefined: (value) => {
        return typeof value === 'undefined';
    },
    isBoolean: (value) => {
        return typeof value === 'boolean';
    },
    isError: (value) => {
        return value instanceof Error && typeof value.message !== 'undefined';
    },
    isDate: (value) => {
        return value instanceof Date;
    },
    isDateTimeString: (value) => {
        if (typeof value !== 'string') {
            return false
        }
        return Date.parse(value);
    },
    isSymbol: (value) => {
        return typeof value === 'symbol';
    },
    isJsonString: (value) => {
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }
        return true;
    },
    isEmptyObject: (object) => {
        return Object.keys(object).length === 0 && object.constructor === Object;
    }
};

export default TypeChecks
