/**
 * Connection Status
 * @type {{isOnline: (function(): boolean), isOffline: (function(): boolean)}}
 */
const ConnectionStatus = {
    isOnline: () => {
        return navigator.onLine;
    },
    isOffline: () => {
        return !navigator.onLine;
    },
}

export default ConnectionStatus
