<template>
    <v-footer
        dark
        class="font-weight-medium white--text"
        dense
        fixed
        padless
    >
        <v-card
            flat
            tile
            class="flex text-center white--text"
            color="indigo"
        >
            <v-card-text class="caption pa-1" v-if="!this.$store.getters.isAuthenticated">
                Version: {{ appVersion }}
                <span class="ma-2">/</span>
                powered by
                <a class="white--text" href="https://www.viebrock.de" target="_blank">Viebrock DatenService GmbH</a>
            </v-card-text>
            <v-card-text class="pa-1" v-if="this.$store.getters.isAuthenticated">
                <v-row>
                    <v-col class="text-left pt-1 pb-1 user">
                        <v-icon class="white--text">mdi-account</v-icon>
                        ({{ getWawiUserToken }}) {{ getUsername }}
                    </v-col>
                    <v-col class="text-right pt-1 pb-1">
                        {{ this.getMandant }}
                        <v-menu right top>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="pa-0 ma-0 indigo" id="btnWechselMandant">mdi-database-refresh</v-icon>
                            </template>
                            <v-list class="overflow-y-auto" max-height="400">
                                <v-subheader>Mandanten</v-subheader>
                                <v-list-item
                                    v-for="mandant in this.getMandanten"
                                    :key="mandant"
                                    @click="changeMandant(mandant)"
                                >
                                    <v-list-item-title>{{ mandant }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import {USER_REQUEST} from "../../store/actions/user";
import {LOADER_HIDE, LOADER_SHOW} from "../../store/actions/loader";
import UserService from "../../services/UserService";

export default {
    name: "Footer",
    data: () => ({}),
    computed: {
        getMandant: function () {
            return this.$store.getters.getProfile.TenantDatabaseName
        },
        getUsername: function () {
            return this.$store.getters.getProfile.DisplayName
        },
        getWawiUserToken: function () {
            return this.$store.getters.getProfile.WawiUserToken
        },
        getMandanten: function () {
            return this.$store.getters.getProfile.AllowedTenantList
        },
        appVersion: function () {
            return process.env.VUE_APP_VERSION
        }
    },
    methods: {
        changeMandant: function (mandant) {
            this.$store.commit(LOADER_SHOW);
            UserService.changeTenant(mandant)
                .then(response => {
                    window.console.log(response);
                    this.$store.dispatch(USER_REQUEST);
                })
                .catch(error => {
                    window.console.log(error.response);
                    this.$store.commit(LOADER_HIDE);
                })
                .finally(() => {

                });
        }
    },
}
</script>

<style scoped>
.v-icon {
    display: inline-flex;
    vertical-align: middle;
    margin-top: -3px;
}

footer .row {
    margin: inherit;
}

#btnWechselMandant {
    height: auto;
    width: auto;
    min-width: auto;
}

.v-icon {
    display: inline-flex;
    vertical-align: middle;
    margin-top: -3px;
}
.user {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
