import {
    AUTH_LOGIN,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";
import {USER_REQUEST} from "../actions/user";
import Vue from 'vue';
import VueCookies from "vue-cookies";
import AuthService from "../../services/AuthService";
Vue.use(VueCookies)

const state = {
    token: Vue.$cookies.get('user-token') || "",
    status: "",
    hasLoadedOnce: false,
    isAuthenticated: false,
};

const getters = {
    //isAuthenticated: state => !!state.token,
    isAuthenticated: state => state.isAuthenticated,
    authStatus: state => state.status
};

const actions = {
    [AUTH_LOGIN]: ({commit, dispatch}, user) => {
        commit('progressbar/start');
        return new Promise((resolve, reject) => {
            commit(AUTH_LOGIN);

            AuthService.login(user.username, user.password)
                .then(response => {
                    const token = response.data.AccessToken
                    Vue.$cookies.set('user-token', token)
                    commit(AUTH_SUCCESS, response)
                    // you have your token, now log in your user :)
                    dispatch(USER_REQUEST)
                    resolve(response)
                })
                .catch(error => {
                    window.console.log(error.response);
                    commit(AUTH_ERROR, error)
                    // if the request fails, remove any possible user token if possible
                    Vue.$cookies.remove('user-token')
                    reject(error)
                })
                .finally(() => {
                    commit('progressbar/done');
                });
        });
    },
    [AUTH_LOGOUT]: ({commit, /*dispatch*/}) => {
        commit('progressbar/start');
        return new Promise((resolve, reject) => {
            AuthService.logout()
                .then(response => {
                    commit(AUTH_LOGOUT)
                    resolve(response)
                })
                .catch(error => {
                    //window.console.log(error.response);
                    commit(AUTH_ERROR, error)
                    // if the request fails, remove any possible user token if possible
                    Vue.$cookies.remove('user-token')
                    reject(error)
                })
                .finally(() => {
                    commit('progressbar/done');
                });
        })
    }
};

const mutations = {
    [AUTH_LOGIN]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.token = resp.data.AccessToken;
        state.hasLoadedOnce = true;
        state.isAuthenticated = true;

        AuthService.setAuthHeader(resp.data.TokenType, state.token)
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
        state.isAuthenticated = false;

        Vue.$cookies.remove('user-token');
        // remove the axios default header
        AuthService.removeAuthHeader()
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
