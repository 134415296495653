<template>
    <v-app>
        <Progressbar/>
        <Navigation v-bind:title="appName" v-bind:app-name="appName"/>

        <InstallPrompt/>

        <Loader/>

        <v-main id="content">
            <v-container>
                <router-view/>
            </v-container>
        </v-main>

        <AppUpdateMessage/>
        <ApiClientErrorMessage/>
        <Footer/>
    </v-app>
</template>

<script>
import Navigation from "./components/app/Navigation";
import {USER_REQUEST} from "./store/actions/user";
import {/*AUTH_ERROR,*/ AUTH_LOGOUT, AUTH_SUCCESS} from "./store/actions/auth";
import Footer from "./components/app/Footer";
import ApiClientErrorMessage from "./components/api/ApiClientErrorMessage";
import Loader from "./components/app/Loader";
import Progressbar from "./components/app/Progressbar";
import SLApi from "./services/SLApi";
import InstallPrompt from "@/components/app/InstallPrompt";
import AppUpdateMessage from "@/components/app/AppUpdateMessage";
import ConfigService from "@/services/ConfigService";

export default {
    name: "App",
    components: {
        Loader,
        ApiClientErrorMessage,
        Footer,
        Progressbar,
        Navigation,
        InstallPrompt,
        AppUpdateMessage,
    },
    computed: {
        title: function () {
            return process.env.VUE_APP_TITLE
        },
        appName: function () {
            return document.body.getAttribute('data-app-name')
        }
    },
    beforeCreate() {
        // Loading config
        ConfigService.init()
    },
    created: function () {
        // Check user login with cookie, if valid, get user data
        let token = this.$cookies.get('user-token');
        if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch(USER_REQUEST);
        } else if (token) {
            this.$store.commit(AUTH_SUCCESS, {data: {AccessToken: token, TokenType: 'LoginId'}});
            this.$store.dispatch(USER_REQUEST);
        }

        const self = this;

        SLApi.client.interceptors.response.use(undefined, function (err) {
            return new Promise(function (/*resolve, reject*/) {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    // if you ever get an unauthorized, logout the user
                    self.$store.dispatch(AUTH_LOGOUT);
                    // you can also redirect to /login if needed !
                    self.$router.push({name: 'Login'})
                }
                throw err;
            });
        });

        // Loading SelectLine API Info with interval
        this.$store.dispatch('info/getInfo');
        if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch('info/addInterval');
        }

        // Navigator connection status
        window.addEventListener('offline', () => {
            this.$store.dispatch('connection/setOnlineState', false)
        })
        window.addEventListener('online', () => {
            this.$store.dispatch('connection/setOnlineState', true)
        })
    }
}
</script>

<style>
@import "css/app.css";
</style>
