import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Info from "../views/Info";
import Home from "../views/Home";
import Inventuren from "../views/Inventuren";
import Inventur from "../views/Inventur";
import InventurLager from "../views/InventurLager";
import Submenu from "@/components/app/Submenu";
import ArtikelBestandAnzeige from "@/views/ArtikelBestandAnzeige";
import ArtikelPermanentInventur from "@/views/ArtikelPermanentInventur";
import ArtikelUmlagerung from "@/views/ArtikelUmlagerung";
import LagerAppLog from "@/views/LagerAppLog";
import Konfiguration from "@/views/Konfiguration";

Vue.use(VueRouter);

/**
 * Check if user is authenticated
 */
const isAuthenticated = () => {
    let authenticated = store.getters.isAuthenticated;
    // if auth status in store is false (after reload), check the user-token cookie
    if (authenticated === false) {
        let token = Vue.$cookies.get('user-token');
        authenticated = token !== null
    }

    return authenticated
};

/**
 * Check if user is not authenticated and call route
 * @param to
 * @param from
 * @param next
 */
const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
};

/**
 * Check if user is authenticated and call route
 * @param to
 * @param from
 * @param next
 */
const ifAuthenticated = (to, from, next) => {
    let authenticated = isAuthenticated();
    if (authenticated) {
        next()
        return
    }

    goToLogin(next);
};

/**
 *
 * @param next
 */
const goToLogin = (next) => {
    next('/login')
};

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/info',
            name: 'Info',
            component: Info,
            beforeEnter(routeTo, routeFrom, next) {
                if (!isAuthenticated) {
                    goToLogin(next)
                }

                store.dispatch('info/getInfo').then(() => {
                    next()
                }).catch(error => {
                    let errorMessage = '';
                    if (typeof error.response !== "undefined") {
                        errorMessage = error.response.data.Message;
                    } else {
                        errorMessage = error.toString();
                    }

                    next({
                        name: 'NotFound',
                        params: {
                            title: `Der Packzettel ${routeTo.params.belegnummer} wurde nicht gefunden!`,
                            message: `${errorMessage}`,
                            messageType: 'error',
                            backToLink: '/packzettel'
                        }
                    })
                })
            }
        },
        {
            path: '/konfiguration',
            name: 'Konfiguration',
            component: Konfiguration,
        },
        {
            path: '/inventur',
            name: 'Inventuren',
            component: Inventuren,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/inventur/:inventur',
            name: 'Inventur',
            component: Inventur,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/inventur/:inventur/:lager',
            name: 'InventurLager',
            component: InventurLager,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/artikel',
            name: 'Artikel',
            component: Submenu,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/artikel/lagerbestand',
            name: 'ArtikelBestand',
            component: ArtikelBestandAnzeige,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/artikel/permanentinventur',
            name: 'ArtikelPermanentInventur',
            component: ArtikelPermanentInventur,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/artikel/umlagerung',
            name: 'ArtikelUmlagerung',
            component: ArtikelUmlagerung,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/artikel/lagerapplog',
            name: 'LagerAppLog',
            component: LagerAppLog,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFound,
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '*',
            redirect: { 'name': 'NotFound'}
        }
    ]
})

router.beforeEach((routeTo, routeFrom, next) => {
    store.commit('progressbar/start');
    next();
});

router.afterEach(() => {
    store.commit('progressbar/done');
});

export default router;
