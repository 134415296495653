import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.css';

import Vue from 'vue'
import vuetify from "@/plugins/vuetify";
import VueCookies from 'vue-cookies'
import '@/filter'
import router from "@/router"
import store from "@/store";
import App from '@/App.vue'
import VueCompositionApi from "@vue/composition-api";
import VueDebounce from 'vue-debounce'
import './registerServiceWorker'
import VueBarcodeScanner from 'vue-barcode-scanner'

// inject barcode scanner with option (add sound effect)
// sound will trigger when it's already scanned
let scannerOptions = {
    //sound: true, // default is false
    //soundSrc: '/static/sound.wav', // default is blank
    //sensitivity: 300, // default is 100
    //requiredAttr: true, // default is false
    //controlSequenceKeys: ['NumLock', 'Clear'], // default is null
    //callbackAfterTimeout: true // default is false
}

Vue.use(VueCookies);
Vue.use(VueCompositionApi);
Vue.use(VueDebounce);
Vue.use(VueBarcodeScanner, scannerOptions)

// Dont show production tip in console
Vue.config.productionTip = false;
//Vue.config.performance = true;
//Vue.config.debug = true;
//Vue.config.devtools = true;

new Vue({
    vuetify,
    render: h => h(App),
    router,
    store,
}).$mount('#app');
