<template>
    <div></div>
</template>

<script>
    export default {
        created() {
            const component = this;
            this.handler = function (e) {
                let target = e.target || e.srcElement;
                // only keyboard events on none input fields!
                if ( !/INPUT|TEXTAREA|SELECT|BUTTON/.test(target.nodeName) ) {
                    component.$emit('keyup', e);
                }
            };
            window.addEventListener('keyup', this.handler);
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.handler);
        }
    }
</script>

<style scoped>
    div {
        display: none;
    }
</style>
